import React,{useState} from 'react'
import { TiArrowSortedDown,TiArrowSortedUp } from "react-icons/ti";
import { Link } from 'react-router-dom';
import MobileDropdown from './MobileDropdown';
import styles from "./menuItemsList.module.css"

// ======= NEW MobileMenuItem cmp (to edit) ========

// function MobileMenuItem({item,depthLevel,title,submenu}) {
//     const [dropdown,setDropdown] =useState(false)
//     const MENU_ITEM ={
//         color: "#FFFFFF",
//         fontStyle: "normal",
//         fontWeight: "600",
//         background:"none",
//         border:"none"
//     }

//     const handleClick=()=>{
//         setDropdown(!dropdown)
//     }


//   return (
//     <li className="nav-item">
//         {
//             item.submenu ? (
//                 <>
//                 <button type="button" aria-haspopup="menu" aria-expanded={dropdown ? "true" : "false"} className="nav-link d-flex justify-content-between align-items-center w-100 text-uppercase font-weight-bold"  style={MENU_ITEM} onClick={handleClick}>
//                 {/* {title} {dropdown?<TiArrowSortedUp/>:<TiArrowSortedDown/>} */}
//                 {depthLevel ===0 ? item.title.toUpperCase():item.title}{' '}
//                 {depthLevel > 0 ? <span>&raquo;</span> : dropdown?<TiArrowSortedUp/>:<TiArrowSortedDown/> }
//             </button>
//             <MobileDropdown title={item.title} dropdown={dropdown} submenu={item.submenu} depthLevel={depthLevel}/>
//                 </>
//             ):(
//                 <span  >{item.title}</span>
//             )
//         }
        
        
//     </li>
//   )
// }




//======= MY MobileMenuItem cmp =========
function MobileMenuItem({item,depthLevel,title,submenu}) {
    const [dropdown,setDropdown] =useState(false)
    const MENU_ITEM ={
        color: "#FFFFFF",
        fontStyle: "normal",
        fontWeight: "600",
        background:"none",
        border:"none"
    }

    const handleClick=()=>{
        setDropdown(!dropdown)
    }


  return (
    <li className="nav-item">
        <button className="nav-link d-flex justify-content-between align-items-center w-100 text-uppercase font-weight-bold"  style={MENU_ITEM} onClick={handleClick}>
            {title} {dropdown?<TiArrowSortedUp/>:<TiArrowSortedDown/>}
        </button>
        <MobileDropdown title={title} dropdown={dropdown} submenu={submenu}/>
    </li>
  )
}
export default MobileMenuItem