import React from 'react'
import CoverImg from 'components/General/CoverImg'
import { Card, Container , Row,Col,Button,Badge} from 'react-bootstrap'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import MainTitle from 'components/General/MainTitle'
import culture from "../../img/culture.jpg"
import Filters from 'components/Agenda Culturel/Filters'
import EventsGrid from 'components/Agenda Culturel/EventsGrid'

function AgendaCulturel() {
 
  return (
    <>
    <CoverImg img={culture}/>
    <Breadcrumb/>
    <Container>
    <MainTitle>Agenda Culturel</MainTitle>
    {/* Evenements a venir */}
    <div className='my-5'>
      <h2 className='my-4 text-start'>Les évènements à venir</h2>
      <Filters type='future'/>
      <EventsGrid type='future'/>
    </div>
    {/* Evenement passés */}
    <div  className='my-5'>
      <h2 className='my-4 text-start'>Les évènements passés</h2>
      <Filters/>
      <EventsGrid type='paste'/>
    </div>
    </Container>
    </>
  )
}

export default AgendaCulturel