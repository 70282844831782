import './style.css';
import { useLocation,useNavigate,Link } from 'react-router-dom';
import { replace } from 'feather-icons';
export default ({}) => {
    const navigate = useNavigate()
    const location =useLocation()
    const path = location.pathname
    
    const wordsToreplaceInUi = {
      FRANCAIS : "FRANÇAIS",
      COOPERATION:"COOPÉRATION",
      EDUCATIVE : "ÉDUCATIVE",
      ETUDIER: "ÉTUDIER",
      ALGERIE : "ALGÉRIE",
      'D ALLEMAND':"D'ALLEMAND",
      'A PARTIR' : "À PARITR",
      "A 15":"À 15",
      GENERAL : "GÉNÉRAL"
    }
    const wordsToreplaceInArray = {
      FRANÇAIS : "FRANCAIS",
      COOPÉRATION:"COOPERATION",
      ÉDUCATIVE : "EDUCATIVE",
      ÉTUDIER: "ETUDIER",
      ALGÉRIE : "ALGERIE",
    }

    // create an array from the current path
    const pathArray = path.split("/").filter(el=>el!=="")
     // replace "-" and "%20" with " "
    const cleanArray = pathArray.map(el=>el.replace(/-|%20/g, " ").toLowerCase())
    const cleanArray2 = cleanArray.map(el=>el.replace(/-|%20/g, " ").toLowerCase())
    
    const handleNavigation =(e)=>{
      // get the postion of curent path inside the path array 
      const position = cleanArray.indexOf(e.target.innerText.replace(/FRANÇAIS|COOPÉRATION|ÉDUCATIVE|ÉTUDIER|ALGÉRIE/g,matched=>wordsToreplaceInArray[matched]).toLowerCase())
      // remove element from routes array
      cleanArray.length=position+1
      // navigate to the new path
      navigate(`/${cleanArray.map(el=>el.replace(/ /g, "-")).join('/')}`)
    }


    
    return (
    <ol className="breadcrumb d-flex justify-content-start" >
    <li><Link to="/">ACCUEIL</Link></li>
    {cleanArray.map((route,index)=>{
      return <li key={index} className="active" style={{cursor:"pointer"}} onClick={handleNavigation}>{route.toUpperCase().replace(/FRANCAIS|COOPERATION|EDUCATIVE|ETUDIER|ALGERIE|D ALLEMAND|A PARTIR|A 15|GENERAL/g,matched=>wordsToreplaceInUi[matched])}</li>
    })}
</ol>);
  }