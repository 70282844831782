import React,{useEffect, useState} from 'react'
import { useLocation,useSearchParams,useNavigate } from 'react-router-dom'
import axios from 'axios';
import {usePayment} from "../../contexts/payment-Context/PaymentContext"
import campus from "../../img/campus-png.png"
import satim from "../../img/satim-logo.png"
import phone from "../../img/satim-number.png"
import { Container, Button } from 'react-bootstrap';
import styles from "./campus.module.css"
import BgShape from 'components/General/BgShape'
import InlineDotsLoader from './components/InlineDotsLoader';
import { BsArrowRepeat } from "react-icons/bs";
import SatimCard from './components/SatimCard';
import { CgDanger } from "react-icons/cg";

function CampusProccessingPage() {

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate()

  const {errValues,setErrorValues,formValues,setFormValues,formErrors,setFormErrors,isOpen,setIsOpen,payment,setPayment,tarif,setTarif,isChecked,setIsChecked,isRecaptcha,setIsRecaptcha} = usePayment()
  const [loading,setLoading] = useState(true)
 



  useEffect(()=>{
    //testLoading() // to delete later
    fetchData() 
    /*
    const id = searchParams.get("orderId")
      var config = {
        method: 'post',
        url: `https://campus.if-algerie.com/api/cibweb/Success?orderid=${id}&total=100`,
        headers: { }
      };
      axios(config)
      .then(function (response) {

        if (response.data.action==="Complete") {
          navigate('/succes',{state:{...response.data}})

        } else if(response.data.action ==="CheckoutError"){
          navigate('/echec',{state:{...response.data}})
        }
        // console.log(formValues.montant)
      
      })
      .catch(function (error) {
        // if error => set loading to false 
        setLoading(false)
        console.log(error);
      });
      */
  },[])

  
 
  // Fetch data 
  const fetchData = ()=>{
    const id = searchParams.get("orderId")
    console.log("xxx id :"+id)
    var config = {
      method: 'post',
      url: `https://campus.if-algerie.com/api/cibweb/Success?orderid=${id}&total=100`,
      headers: { }
    };
    axios(config)
    .then(function (response) {

      if (response.data.action==="Complete") {
        navigate('/succes',{state:{...response.data,apiId:id}})

      } else if(response.data.action ==="CheckoutError"){
        navigate('/echec',{state:{...response.data}})
      }
      // console.log(formValues.montant)
    
    })
    .catch(function (error) {
      // if error => set loading to false 
      setLoading(false)
      console.log(error);
    });
  }

  // reFetch  Data
  const RefetchData = ()=>{
    // show loader
    setLoading(true)
    fetchData() // to uncomment later
    //testLoading() // to delete later
  }

   // test loading 
   const testLoading =()=>{
    // setLoading(true)
    setTimeout(() => {
      setLoading(false)
      navigate("/succes")
    }, 5000);
  }

  return (
    // <div className='d-flex align-items-center justify-content-center' style={{width:"100vw",height:"100vh"}}>
    //   <p>Chargement en cours ...</p>
    // </div>
    <div style={{backgroundColor:"whitesmoke",zIndex:"1", height:"100vh" }} className={`position-relative overflow-hidden pb-3 pb-md-0`}>
    <BgShape className="d-none d-sm-block" type="red"  right="-80px" top="-50px" rotate="-140deg"/>
    <BgShape type="blue"  right="-50px" top="750px" rotate="-150deg"/>
    <BgShape className="d-none d-sm-block" type="blue" left="-80px" top="400px" rotate="140deg"/>
    <Container className={` ${styles['processing']}`}>
      <div className='py-3 d-flex mb-5 flex-lg-row align-items-center justify-content-between'>
        <img src={campus} alt="Campus France" width='160px'/>
        <img src={satim} alt="satim" width='200px'/>
      </div>
      <div className=' text-center pb-5 mt-2'>
        {!loading && <div className='text-danger' style={{fontSize:"4rem"}}><CgDanger/></div>}
        {!loading ? 
        <p className=' fs-4'>Un problème est survenue lors du traitement de votre demande <br/> Veuillez réessayer</p>
        :
        <p className=' fs-4 mb-5'>Le traitement de votre paiement est en cours <br/> patientez SVP</p>}
        
        <div className='d-flex justify-content-center align-items-center mb-5 mt-2'>
          {loading ?
          // <span className={styles['four-dots']}></span>
          <InlineDotsLoader className="mt-2" />
          :
          <button className={styles.retry} onClick={()=>RefetchData()}><BsArrowRepeat/> Réessayer </button> }
        </div>
      </div>
      <div className='d-flex justify-content-center justify-content-md-end '>
          <img src={phone} width="180px"/>
      </div>
    </Container>
    </div>
  )
}

export default CampusProccessingPage