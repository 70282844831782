import React, {useState,useEffect, useContext} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form,Row,Col,Button } from 'react-bootstrap'
import exit from "../../../img/exit.png"
import PriceCard from './PriceCard';
import CityMenuCard from 'components/Cities/CityMenuCard';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { IoHelpCircleOutline, IoStopCircleOutline, IoWarningOutline } from "react-icons/io5";
import Popover from 'react-bootstrap/Popover';
import {usePayment} from "../../../contexts/payment-Context/PaymentContext"
const PaymentMessagePopUp = ({onClose}) => {
  
  const {formValues,setFormValues,tarif,setTarif,tObject,setTObject} = usePayment()
    
  const tarifPopover = (
    <Popover id="popover-basic">
      <Popover.Body>
      Avant d’effectuer votre paiement, veuillez-vous renseigner sur le statut à sélectionner dans le menu déroulant, ce qui vous permettra de connaître le tarif adapté à votre situation. En cas d’erreur, aucun remboursement ne sera possible 
      </Popover.Body>
    </Popover>
  );
  const tarifs = [{
    'id':1,
    'title':'Je suis boursier',
    'montant':0

  },{
    'id':2,
    'title':'J’ai un baccalauréat français (élève scolarisé cette année au LIAD)',
    'montant':0

  },{
    'id':3,
    'title':'Je suis accepté via Campus Art',
    'montant':10000

  },{
    'id':4,
    'title':'Je postule pour un DFMS/ DFMSA',
    'montant':10000

  },{
    'id':5,
    'title':'Je suis un agent de l’Institut français d’Algérie',
    'montant':0

  },{
    'id':6,
    'title':'Je suis admis dans le cadre d’une convention (co-diplomation, cotutelle ou codirection de thèse)',
    'montant':0

  },{
    'id':7,
    'title':'J’ai été absent à l’entretien',
    'montant':10000

  },{
    'id':8,
    'title':'Je ne suis dans aucun de ces cas',
    'montant':20000

  },]
  const tarifsList = tarifs.map((tarif,index)=>{
    return <option key={tarif.id} value={tarif.id}>{`${tarif.title}`}</option>
  })
  const handleChange = (e) => {
    const { name, value } = e.target;
    // ==== use it later in the seccussefulPaymentpage
      // const newDate = name==="naissance"&&`${value.slice(8,10)}-${value.slice(5,7)}-${value.slice(0,4)}`
      // name==="naissance"&&console.log(newDate)

      console.log(name+ ' : '+ value)
      setFormValues({ ...formValues, [name]: value});
      if(name==='tarif'){
        var v = tarifs.find(x=>x.id===Number(value)).montant
        console.warn(v)
        setFormValues({ ...formValues, [name]: v,montant:v});
            setTarif(v)
            setTObject(value)
            setFormValues({ ...formValues, montant:v, tarif:v})
      }
  };
  const handleClose = ()=>{
    console.log(formValues.montant)
    console.log(formValues.tarif)
    console.log("xxx")
    console.log(tarif)
    if(formValues.tarif && formValues.tarif!=='0' && tarif!=='0')
      onClose()
    
  }
  console.log(formValues.tarif)
  console.log("xxx")
    console.log(tarif)
  return (
    <div className="bg-white p-3">
      <div className="d-flex items-center justify-end mb-2">
        <button
          onClick={onClose}
          className="bg-none border-0 bg-white ms-auto"
        >
          <img src={exit} width="22"/>
        </button>
      </div>
      <p
        className="text-center"
        style={{ fontSize: "16px", lineHeight: "1.6" }}
      >
        </p>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridState">
            <Form.Label>Votre statut de dossier Étude en France  *</Form.Label>
            <OverlayTrigger trigger="click" placement="top" overlay={tarifPopover}>
              <span style={{cursor:"pointer"}}> <IoHelpCircleOutline className='blue-ifa fs-5 mb-1'/></span>
            </OverlayTrigger>
            <Form.Select className="rounded-0" defaultValue="Choose..."  name="tarif" value={tObject} onChange={handleChange}>
              <option></option>
              {tarifsList}
            </Form.Select>
            
          </Form.Group>
          {formValues.tarif!==0?<></>:<> 
          
          <p className='text-danger mt-1 fw-light' style={{fontSize:"18px"}}><IoWarningOutline className='text-danger fs-5 mb-1'/>{" Vous n'avez pas de frais à payer "}</p>
          </>}
        </Row>
      <div>
          <span>Choisissez correctement votre espace de rattachement</span>
          <Row className="py-5">
            
                <CityMenuCard menu={ {
        title:"Alger",
        url:"https://online.vfsglobal.dz/CAMPUSFRANCE",
      }} />
                <CityMenuCard menu={{
        title:"Annaba",onClose:handleClose
      }} />
      <CityMenuCard menu={{
        title:"Constantine",onClose:handleClose
      }} />
      <CityMenuCard menu={{
        title:"Oran",onClose:handleClose
      }} />
      <CityMenuCard menu={{
        title:"Tlemcen",onClose:handleClose
      }} />
          </Row>
        </div>
    </div>
  );
}

export default PaymentMessagePopUp