import React from 'react'
import { TiArrowSortedDown,TiArrowSortedUp } from "react-icons/ti";
import { Link } from 'react-router-dom';
import MobileMenuItem from './MobileMenuItem';
import styles from "./menuItemsList.module.css"

const MENU_ITEM ={
    color: "#FFFFFF",
    fontStyle: "normal",
    fontWeight: "600",
    background:"none",
    border:"none"
}

// ======== NEW dropdown cmp (to edit) ============
// function MobileDropdown({submenu,dropdown,title,depthLevel}) {
//   depthLevel = depthLevel + 1;

//     // const submenuList = submenu.map((sub,index)=>{
//     //     return title!=="agenda"? <li key={index}><Link className="text-white lh-4" to={sub.url}>{sub.title}</Link></li>
//     //     :
//     //     <li className="nav-item">
//     //     <button className="nav-link d-flex justify-content-between align-items-center w-100 text-uppercase font-weight-bold"  style={MENU_ITEM} >
//     //         {sub.title} {dropdown?<TiArrowSortedUp/>:<TiArrowSortedDown/>}
//     //     </button>
        
//     //     {/* <MobileDropdown title={sub.title} dropdown={dropdown} submenu={sub.submenu}/> */}
//     // </li>
//     // })

//   return (
//     <ul className={`text-white list-unstyled ps-3 lh-lg ${dropdown==false?"d-none":""}`}  >
//             {submenu.map((sub,index)=>{
//               return  <MobileMenuItem item={submenu} key={index} depthLevel={depthLevel} className={styles.dropItem} />
//             })}
//     </ul>
//   )
// }



//======= MY MobileDropdown cmp ========== 
function MobileDropdown({submenu,dropdown,title}) {
  const submenuList = submenu.map((sub,index)=>{
    return <li key={index}><Link className="text-white lh-4" to={sub.url}>{sub.title}</Link></li>


      // return title!=="agenda"? <li key={index}><Link className="text-white lh-4" to={sub.url}>{sub.title}</Link></li>
      // :
      // <li className="nav-item">
      // <button className="nav-link d-flex justify-content-between align-items-center w-100 text-uppercase font-weight-bold"  style={MENU_ITEM} >
      //     {sub.title} {dropdown?<TiArrowSortedUp/>:<TiArrowSortedDown/>}
      // </button>
      // </li>

      
      {/* <MobileDropdown title={sub.title} dropdown={dropdown} submenu={sub.submenu}/> */}
  })

return (
  <ul className={`text-white list-unstyled ps-3 lh-lg ${dropdown==false?"d-none":""}`}  >
          {submenuList}
  </ul>
)
}
export default MobileDropdown