import React from 'react'
import {Container} from 'react-bootstrap';


function Bandeau({children,className,link}) {
  return (
    <>
        <div className={`${className} py-5 my-5'`} style={{backgroundColor:"rgb(205, 232, 252)"}}>
            <Container>
                <a href={link} target="_blank" className={`${className} py-5 fs-3 text-black bg-l`}  >
                    {children}
                </a>
            </Container>
        </div>
    </>
  )
}

export default Bandeau