import MainTitle from 'components/General/MainTitle'
import React from 'react'
import { Container} from 'react-bootstrap';
import CoverImg from 'components/General/CoverImg'
import { useParams } from 'react-router-dom';
import Breadcrumb from 'components/breadcrumb/breadcrumb';
import { Events } from 'data/Events';


function EventPage() {
    const {id} = useParams()
    const event = Events.filter(event=>event.title === id)[0]
  return (
    <>
      <CoverImg
        img={
          event.image
        }
      />
      <Breadcrumb/>
      <Container className="w-md-75">
        <MainTitle className="mt-5">{id}</MainTitle>
        <div className='p-4 bg-blue-ifa-light d-flex justify-content-between fs-5 my-3'>
            <div className='text-start'>
                <span className="fw-bold me-2 blue-ifa">Lieu:</span>
                <span className="mt-2">{event.region}</span>
            </div>
            <div className='text-start'>
                <span className="fw-bold me-2 blue-ifa">Date:</span>
                <span className="mt-2">{event.date}</span>
            </div>
        </div>
        
        <div>
          <img
            className="w-100 mb-4"
            src={event.image}
          ></img>
        </div>
        <div>
          <p className="text-start lh-lg text-black-50">
            Most of the time in our programming life, when we are stuck there is
            always Google or Stack Overflow that can provide quick help. But
            this time it will not always save you. First of all, googling for
            help in this case is quite tricky. For example, say you want to
            highlight a word on cursor – you might search for vs code extension
            how to get total line... or something similar. But let me tell you,
            most of the time it will direct you to the real extension itself or
            give you manual on how to use VS Code. One way you can make it
            easier for yourself is by adding the "API" keyword in your search,
            like vs code extension api how to .... Also, it is pretty hard to
            find the relevant answers in Google, because the developer community
            is not that huge, and VS Code extensions may look intimidating for
            many newcomers. But truthfully, it is not exactly that hard. That’s
            why sometimes the best way to learn how to develop a VS Code
            extension is by reading the documentation or the code.
          </p>
        </div>
      </Container>
    </>
  );
}

export default EventPage