import React,{useState} from 'react'
import SocialIcon from './SocialIcon'
import { Container,Button } from 'react-bootstrap'
import { HiMail } from 'react-icons/hi';
import Modal from "../../pages/newsletter/Modal"
import NewsLetterForm from 'pages/newsletter/NewsLetterForm';
import NewsletterButton from './NewsletterButton';
import { MdLocationOn } from "react-icons/md";
import { FaDirections } from "react-icons/fa";



const socials=[
    {
        type:"Instagram",
        link:"https://instagram.com/ifalgerie?igshid=YmMyMTA2M2Y= "
    },
    {
        type:"Youtube",
        link:"https://www.youtube.com/channel/UCwFxKRpsl-N5cSJKEYbDyyQ "
    },
    {
        type:"Tiktok",
        link:"https://vm.tiktok.com/ZMNcrjx7v/ "
    },
    {
        type:"LinkedIn",
        link:"#"
    }

]

const socialInfos=[
    {
        region:"algerie",
        fb:"https://www.facebook.com/IFAlgerie/",
        adresse:"Institut français d'Alger - 7, rue Hassani Issad - 16000 Alger - Algérie.",
        acceuil:"Du Samedi au Jeudi de 8h30 à 16h30",
    },
    {
        region:"alger",
        fb:"https://www.facebook.com/algerifa",
        adresse:"Institut français d'Alger - 7, rue Hassani Issad - 16000 Alger - Algérie.",
        acceuil:"Du Samedi au Jeudi de 8h30 à 16h30",
    },
    {
        region:"oran",
        fb:"https://www.facebook.com/institutfrancaisoran",
        adresse:"Institut Français d'Oran - 112, rue Larbi ben M'Hidi - 31009 - Oran, Algérie.",
        acceuil:"Du samedi au jeudi, de 9h à 17h30",
    },
    {
        region:"constantine",
        fb:"https://www.facebook.com/IFConstantine",
        adresse:"Institut français de Constantine - 1 boulevard de l'Indépendance –25 000 Constantine",
        acceuil:"",
    },
    {
        region:"annaba",
        fb:"https://www.facebook.com/institutfrancaisannaba",
        adresse:"Institut français d'Annaba - 8 Bd du 1er Novembre 1954 Annaba",
        acceuil:"Du samedi au jeudi : 8h30 à 17h00",
    },
    {
        region:"tlemcen",
        fb:"https://www.facebook.com/algerifa",
        adresse:"Institut Français Tlemcen, 1 rue Commandant Djaber - 13000, Tlemcen.",
        acceuil:"",
    }
]

const socialIconsList = socials.map((el,index)=>{
    return <SocialIcon key={index} type={el.type} link={el.link} size="small"/>
})



function SocialSection({className,children,institut,ville}) {
    const [isOpen,setIsOpen] = useState(false)
    // get the current city
    const currentCity = socialInfos.filter((item)=>{
        return item.region==ville
    })

    {console.log("///-----------------///")}
                {console.log(currentCity[0])}
                {console.log(ville)}
                {console.log(currentCity[0].fb)}
                {console.log(currentCity)}
  return (
    <div style={{backgroundColor:"whitesmoke",borderTop:"solid 1px rgb(196, 197, 197)"}}>
        <Container className='d-flex flex-column gap-3  align-items-between justify-content-center py-md-2 py-4 '>
        <div className='d-flex flex-column gap-3 flex-sm-row align-items-center justify-content-between py-md-2 py-4 '>
                <div>
                    <NewsletterButton size="md"/>
                </div>
                <div className={`${className} d-flex justify-content-center gap-2 gap-sm-3 fs-2`}>
                <SocialIcon type="Facebook" link={currentCity[0].fb} size="small"/>
                {socialIconsList}
                </div>
        </div>
        </Container>


        {institut&&
        <div style={{backgroundColor:"rgb(0, 146, 213)"}} className="py-3 text-white">
            <Container>
                {console.log("///-----------------///")}
                {console.log(currentCity[0])}
                {console.log(ville)}
                <ul className=" list-unstyled flex-column gap-2 flex-lg-row d-flex align-items-center justify-content-between mb-0" >
                    <li><MdLocationOn className='mb-1'/> {currentCity[0].adresse}</li>
                    <li>{currentCity[0].acceuil!=""?<><FaDirections className='mb-1 me-1'/><b>ACCUEIL ET ORIENTATION :</b>{currentCity[0].acceuil}</>
                    :null} </li>
                    {/* <li><FaDirections className='mb-1 me-1'/><b>{currentCity[0].adresse!=""?"ACCUEIL ET ORIENTATION :":"NO"}ACCUEIL ET ORIENTATION :</b> {currentCity[0].acceuil} </li> */}
                    {/* <li><MdLocationOn className='mb-1'/> Institut français d'Alger - 7, rue Hassani Issad - 16000 Alger - Algérie</li>
                    <li><FaDirections className='mb-1 me-1'/><b>ACCUEIL ET ORIENTATION :</b> Du Samedi au Jeudi de 8h30 à 16h30 </li> */}
                </ul>
            </Container>
            </div>
        }
    </div>
  )
}

export default SocialSection