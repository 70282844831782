import React from 'react'
import styles from "./CoursDeFrançais.module.css"
import MainHeader from '../header/MainHeader'
import CoverImg from 'components/General/CoverImg'
import { Card, Container , Row,Col,Button,Badge,ListGroup,Table} from 'react-bootstrap'
import Footer from '../footer/Footer'
import breadcrumb from 'components/breadcrumb/breadcrumb'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import cover from '../../img/cours-de-français.jpg'
import alger from "../../img/alger.jpg"
import constantine from "../../img/constantine.jpg"
import annaba from "../../img/annaba.jpg"
import oran from "../../img/oran.jpg"
import tlemcen from "../../img/tlemcen.jpg"
import { FaCheckCircle ,FaHandPointRight} from "react-icons/fa";
import { Link,useLocation } from 'react-router-dom'
// import {TbDiscount} from 'react-icons/tb'
import { BiDoorOpen, BiLogIn } from "react-icons/bi";
import { ImPriceTag } from "react-icons/im";




function CoursDeFrançaisGeneral() {
  const location = useLocation()

  return (
    <div style={{backgroundColor:"whitesmoke"}} className="py-4">
        <CoverImg img={cover}/>
        <Breadcrumb/>
        <Container>
        <div className='mt-5'>
            <p className='text-start lh-lg'>
            Le  Département  de  langue  française  vous  propose  un  cursus  de  formation  adapté  à
            vos besoins, avec différents niveaux ( élémentaire, intermédiaire, avancé, spécialisé).
            </p>
        </div>
         <Row className='my-5'>
            <Col md={6} className="mb-4">
                    <Link to='/alger/cours-de-francais'>
                    <Card className="card">
                        <span className="zoom-effect-container">
                          <img src={alger} className="card-img-top" />
                        </span>
                        <Card.Body className='pb-0'>
                          <Card.Title
                            className='pt-2'
                            style={{color:'rgba(5, 131, 189, 0.932)'}}
                          >
                            <h4>Alger</h4>
                          </Card.Title>
                        </Card.Body>
                        <Card.Body
                          style={{ display: "flex", justifyContent: "space-between" }}
                        >
                        </Card.Body>
                    </Card>
                  </Link>
            </Col>
            <Col md={6} className="mb-4">
                <Link to='/alger/cours-de-francais'>
                        <Card className="card">
                            <span className="zoom-effect-container">
                            <img src={constantine} className="card-img-top" />
                            </span>
                            <Card.Body className='pb-0'>
                            <Card.Title
                                className='pt-2'
                                style={{color:'rgba(5, 131, 189, 0.932)'}}
                            >
                                <h4>Constantine</h4>
                            </Card.Title>
                            </Card.Body>
                            <Card.Body
                            style={{ display: "flex", justifyContent: "space-between" }}
                            >
                            </Card.Body>
                        </Card>
                </Link>
            </Col>
            <Col md={6} className="mb-4">
            <Link to='/alger/cours-de-francais'>
                    <Card className="card">
                        <span className="zoom-effect-container">
                          <img src={annaba} className="card-img-top" />
                        </span>
                        <Card.Body className='pb-0'>
                          <Card.Title
                            className='pt-2'
                            style={{color:'rgba(5, 131, 189, 0.932)'}}
                          >
                            <h4>Annaba</h4>
                          </Card.Title>
                        </Card.Body>
                        <Card.Body
                          style={{ display: "flex", justifyContent: "space-between" }}
                        >
                        </Card.Body>
                    </Card>
                  </Link>
            </Col>
            <Col md={6} className="mb-4">
            <Link to='/alger/cours-de-francais'>
                    <Card className="card">
                        <span className="zoom-effect-container">
                          <img src={oran} className="card-img-top" />
                        </span>
                        <Card.Body className='pb-0'>
                          <Card.Title
                            className='pt-2'
                            style={{color:'rgba(5, 131, 189, 0.932)'}}
                          >
                            <h4>Oran</h4>
                          </Card.Title>
                        </Card.Body>
                        <Card.Body
                          style={{ display: "flex", justifyContent: "space-between" }}
                        >
                        </Card.Body>
                    </Card>
                  </Link>
            </Col>
            <Col md={6} className="mb-4">
            <Link to='/alger/cours-de-francais'>
                    <Card className="card">
                        <span className="zoom-effect-container">
                          <img src={tlemcen} className="card-img-top" />
                        </span>
                        <Card.Body className='pb-0'>
                          <Card.Title
                            className='pt-2'
                            style={{color:'rgba(5, 131, 189, 0.932)'}}
                          >
                            <h4>Tlemcen</h4>
                          </Card.Title>
                        </Card.Body>
                        <Card.Body
                          style={{ display: "flex", justifyContent: "space-between" }}
                        >
                        </Card.Body>
                    </Card>
                  </Link>
            </Col>
         </Row>

        </Container>
    </div>
      )
}

export default CoursDeFrançaisGeneral