import essa from "../img/essa-img.jpg"
import peh from "../img/peh-img.jpg"
import liad from "../img/liad-img.jpg"
import campus from "../img/campus-france.jpg"
import bourses from "../img/bourses-img.jpg"
import alumni from "../img/alumni-img.jpg"



const menuItems = [
    {
        title:"institut",
        submenu:[
          {
            title:"présentation",
            url:"institut/presentation"
          },
          {
            title:"Nos missions",
            url:"institut/nos-missions",
            hide:['alger','oran','constantine','tlemcen','annaba']
          },
          {
            title:"L'équipe",
            url:"institut/equipe"
          }
            
            
        ]
    },
    {
        title:"test et examnes",
        submenu:[
          {
            title:"Présentation",
            url:"tests-et-examens/presentation"
          },
          {
            title:"TCF",
            url:"tests-et-examens/tcf"
          },
          {
            title:"DELF & DALF",
            url:"tests-et-examens/delf-et-dalf"
          }
          
        ]
    },
    {
      title:"cours de français",
      submenu:[
        {
          title:"Présentation",
          url:"cours-de-francais/presentation"
        },
        {
          title:"Inscription",
          url:"cours-de-francais/inscription"
        },
        {
          title:"Calendrier des Sessions",
          url:"cours-de-francais/calendrier-des-sessions"
        }
      ]
    },
    {
        title:"etudes",
        submenu:[
          
            {
                title: 'France',
                submenu: [
                    {
                        title:"Campus France",
                        url:"etudes/en-france/campus-france",
                        img:campus
                    },
                    {
                        title:"France Alumni",
                        url:"etudes/en-france/france-alumni",
                        img:alumni
                    },
                    {
                        title:"Bourses",
                        url:"etudes/en-france/bourses",
                        img:bourses
                    }
                  
                ],
            },
            {
                title: 'Algérie',
                submenu:[
                    {
                        title:"LIAD",
                        url:"etudes/en-algerie/liad",
                        img:liad
                    },
                    {
                        title:"PEH",
                        url:"etudes/en-algerie/peh",
                        img:peh
                    },
                    {
                        title:"ESSA",
                        url:"etudes/en-algerie/essa",
                        img:essa
                    }
                ]
            }
        ]
    },
    {
        title:"culture",
        submenu:[
          
            {
                title: 'Agenda',
                url:"culture/agenda",
                submenu: [
                    {
                        title:"Musique",
                        url:"culture/agenda/musique"
                    },
                    {
                        title:"Théâtre",
                        url:"culture/agenda/theatre"
                    },
                    {
                        title:"Danse",
                        url:"culture/agenda/danse"
                    },
                    {
                        title:"Expo",
                        url:"culture/agenda/expo"
                    },
                    {
                      title:"Cinéma",
                      url:"culture/agenda/cinema"
                    },
                    {
                      title:"Conférences et rencontres",
                      url:"culture/agenda/conferences-et-rencontres"
                    },
                    {
                        title:"Jeunesse",
                        url:"culture/agenda/jeunesse"
                    },
                  
                ],
            },
            {
                title: 'Nos actions culturelles',
                url:"culture/nos-actions-culturelles"
            }
        ]
    },
    {
      title:"mediathèque",
      submenu:[
        {
          title:"Présentation",
          url:"mediatheque/presentation"
        },
        {
          title:"Inscription",
          url:"mediatheque/inscription"
        },
        {
          title:"Ateliers",
          url:"mediatheque/ateliers"
        },
        {
          title:"Culturethèque",
          url:"mediatheque/culturetheque"
        },
        
      ]
    },
  ]
  
const mobileMenuItems =[
    {
        title:"institut",
        submenu:[
          {
            title:"présentation",
            url:"institut/presentation"
          },
          {
            title:"Nos missions",
            url:"institut/nos-missions",
            hide:['alger','oran','constantine','tlemcen','annaba']
          },
          {
            title:"L'équipe",
            url:"institut/equipe"
          }
            
            
        ]
    },
    {
        title:"test et examnes",
        submenu:[
          {
            title:"Présentation",
            url:"tests-et-examens/presentation"
          },
          {
            title:"TCF",
            url:"tests-et-examens/tcf"
          },
          {
            title:"DELF & DALF",
            url:"tests-et-examens/delf-et-dalf"
          }
        ]
    },
    {
      title:"cours de français",
      submenu:[
        {
          title:"Présentation",
          url:"cours-de-francais/presentation"
        },
        {
          title:"Inscription",
          url:"cours-de-francais/inscription"
        },
        {
          title:"Calendrier des Sessions",
          url:"cours-de-francais/calendrier-des-sessions"
        }
      ]
    },
    {
      title:"etudes",
      submenu: [
        {
            title:"Campus France",
            url:"etudes/en-france/campus-france",
            img:campus
        },
        {
            title:"France Alumni",
            url:"etudes/en-france/france-alumni",
            img:alumni
        },
        {
            title:"Bourses",
            url:"etudes/en-france/bourses",
            img:bourses
        },
        {
          title:"LIAD",
          url:"etudes/en-algerie/liad",
          img:liad
      },
      {
          title:"PEH",
          url:"etudes/en-algerie/peh",
          img:peh
      },
      {
          title:"ESSA",
          url:"etudes/en-algerie/essa",
          img:essa
      }
      
      ] 
    },
    {
      title:"agenda",
      submenu: [
        {
          title:"Musique",
          url:"culture/agenda/musique"
      },
      {
          title:"Théâtre",
          url:"culture/agenda/theatre"
      },
      {
          title:"Danse",
          url:"culture/agenda/danse"
      },
      {
          title:"Expo",
          url:"culture/agenda/expo"
      },
      {
        title:"Cinéma",
        url:"culture/agenda/cinema"
      },
      {
        title:"Conférences et rencontres",
        url:"culture/agenda/conferences-et-rencontres"
      },
      {
          title:"Jeunesse",
          url:"culture/agenda/jeunesse"
      },
      {
        title: 'Nos actions culturelles',
        url:"culture/nos-actions-culturelles"
    }
      
    ]
        
    },
   
    {
      title:"mediathèque",
      submenu:[
        {
          title:"Présentation",
          url:"mediatheque/presentation"
        },
        {
          title:"Inscription",
          url:"mediatheque/inscription"
        },
        {
          title:"Ateliers",
          url:"mediatheque/ateliers"
        },
        {
          title:"Culturethèque",
          url:"mediatheque/culturetheque"
        }
        
      ]
    },
  
]
  export  {menuItems,mobileMenuItems}