import React from 'react'
import { Card} from "react-bootstrap";
import { Link } from "react-router-dom";




function CityMenuCard({menu}) {
    const {title,url,onClose} = menu
    
  return (
    <button
          onClick={url?()=> window.location.href=url :onClose}
          className="bg-none border-0 bg-white ms-auto"
        >
          <Card className="card ">
            <Card.Title
              className="mb-0"
              style={{ color: "rgba(5, 131, 189, 0.932)" }}
            >
              <h6 className="mb-0 p-3 fw-bold">{title}</h6>
            </Card.Title>
          </Card>
        </button>
  )
}

export default CityMenuCard