import React from 'react'
import { Card,Col,Button,Badge} from 'react-bootstrap'

import img1 from "../../img/event-sila.jpg"
import img2 from "../../img/ComponentTMP_4-image.png";
import img3 from "../../img/event-photographie.jpg";
import img4 from "../../img/ComponentTMP_4-image3.png";
import img5 from "../../img/event-liad.jpeg";
import img6 from "../../img/event-writing.jpg";
import img7 from "../../img/ComponentTMP_4-image2.png";
import pImg1 from "../../img/event-p-stage.jpg"
import pImg2 from "../../img/event-p-socio.jpeg"
import pImg3 from "../../img/event-p-concour.jpg"


const futurEventImgs = [img1,img2,img3,img4,img5,img6,img7]
const pastEventsImgs = [pImg1,pImg2,pImg3]

const backgrounds = (city)=>{
    switch (city) {
        case "alger":
            return "danger"
            break;
        case "constantine":
            return "warning"
            break;
        case "oran":
            return "success"
            break;
        case "annaba":
            return "black"
            break;
        case "telemcen":
            return "secondary"
            break;

    
        default:
            break;
    }
}

function EventCard({event,id,type}) {
  return (
    <Col md={4}>
          <Card className="card mb-3" >
            <span className="zoom-effect-container">
              <h4 style={{ top: "10px", position: "absolute" }}>
                <Badge
                  style={{
                    position: "absolute",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    textTransform:"uppercase"
                  }}
                  bg={backgrounds(event.city)}
                >
                  {event.city}
                </Badge>
              </h4>
              <h5 style={{ bottom: "15px", position: "absolute" }}>
                <Badge  
                    style={{
                    position: "absolute",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    color:"black"
                  }}
                  bg="white">
                    {event.date}
                </Badge>
              </h5>
              {/* <img src={type==='future'?futurEventImgs[id]:pastEventsImgs[id]} className="card-img-top" /> */}
              <img src={event.image} className="card-img-top" style={{height:"400px",objectFit:"cover"}} />
            </span>
            <Card.Body>
              <Card.Title
                style={{ textAlign: "initial", color: "rgb(20, 20, 20)" }}
              >
                {event.title}
              </Card.Title>
              <Card.Text
                style={{
                  textAlign: "initial",
                  marginLeft: "-3px",
                  color: "rgb(119, 117, 119)",
                }}
              >
                 {event.description}
              </Card.Text>
            </Card.Body>
            <Card.Body
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                variant="link"
                style={{
                  color: "black",
                  textDecorationColor: "#ffed00",
                  textDecorationThickness: "3px",
                }}
              >
                Lire la suite
              </Button>
              <Button variant="outline-danger" style={{ color: "black",transform:"capitalize" }}>
                {event.tag}
              </Button>
            </Card.Body>
          </Card>
    </Col>
  )
}

export default EventCard