import React,{useState} from "react";
import { Button,Form } from "react-bootstrap";
import  ReactDOM  from "react-dom";
import styles from "../campus.module.css"

const modalStyle={
    position:"fixed",
    width:"700px",
    top:"50%",
    left:"50%",
    transform:"translate(-50%,-50%)",
    zIndex:'200',
}

const overlayStyle={
    position:"fixed",
    top:"0",
    bottom:"0",
    left:"0",
    right:"0",
    backgroundColor:"rgba(0,0,0,.5)",
    zIndex:"100"
}
function Modal({ children, open,onClose }) {
  if (!open) return null;

  return ReactDOM.createPortal(
    <>
        <div style={overlayStyle} onClick={()=>onClose(false)}></div>
        <div className={styles.modal} >
            {children}
        </div>
    </>
  ,document.getElementById('portal'));
}

export default Modal;
