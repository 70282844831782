
import React, { useState } from 'react';
import {Button,Card} from 'react-bootstrap';
import ifa from "../../img/ifa-logo.png"


function ConfirmationPopUp({onClose,values,type}) {
  
  const [email,setEmail] =useState("")
  const [emailError,setEmailError]=useState("")
  const handleChange=(e)=>{
    setEmail(e.target.value)
  }
  // email reçu de paiement
  const handleEmail = (values,mail)=>{
    const config={
      SecureToken: 'b68c6b3e-451b-4147-b2a9-a7eb1d9be99d',
      To: mail,
      From: "institutfrancais.paiement@gmail.com",
      Subject: "Reçu de paiement -l'institut Français d'Algérie-",
      Body: `
      <head> \
      <style> \
      .email {
        max-width: 480px;
        margin: 1rem auto;
        border-radius: 10px;
        border-top: rgb(0, 146, 213) 2px solid;
        border-bottom: rgb(0, 146, 213) 2px solid;
        box-shadow: 0 2px 18px rgba(0, 0, 0, 0.2);
        padding: 1.5rem;
        font-family: Arial, Helvetica, sans-serif;
      }
      .email .email-head {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        padding-bottom: 1rem;
      }
      .email .email-head .head-img {
        max-width: 240px;
        padding: 0 0.5rem;
        display: block;
        margin: 0 auto;
      }
      .email .email-head .head-img img {
        width: 100%;
      }
      .email-body .invoice-icon {
        max-width: 80px;
        margin: 1rem auto;
      }
      .email-body .invoice-icon img {
        width: 100%;
      }
      .email-body .body-text {
        padding: 2rem 0 1rem;
        text-align: center;
        font-size: 1.15rem;
      }
      .email-body .body-text.bottom-text {
        padding: 2rem 0 1rem;
        text-align: center;
        font-size: 0.8rem;
      }
      .email-body .body-text .body-greeting {
        font-weight: bold;
        margin-bottom: 1rem;
      }
      .email-body .body-table {
        text-align: left;
      }
      .email-body .body-table table {
        width: 100%;
        font-size: 1.1rem;
      }
      .email-body .body-table table .total {
        background-color: hsla(4, 67%, 52%, 0.12);
        border-radius: 8px;
        color: rgb(0, 146, 213);
      }
      .email-body .body-table table .item {
        border-radius: 8px;
        color: rgb(0, 146, 213);
      }
      .email-body .body-table table th,
      .email-body .body-table table td {
        padding: 10px;
      }
      .email-body .body-table table tr:first-child th {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }
      .email-body .body-table table tr td:last-child {
        text-align: right;
      }
      .email-body .body-table table tr th:last-child {
        text-align: right;
      }
      .email-body .body-table table tr:last-child th:first-child {
        border-radius: 8px 0 0 8px;
      }
      .email-body .body-table table tr:last-child th:last-child {
        border-radius: 0 8px 8px 0;
      }
      .email-footer {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
      }
      .email-footer .footer-text {
        font-size: 0.8rem;
        text-align: center;
        padding-top: 1rem;
      }
      .email-footer .footer-text a {
        color: rgb(0, 146, 213);
      }
    </style>
  </head>
  <body>
    <div class="email">
      <div class="email-head">

      </div>
      <div class="email-body">
        <div class="body-text">
          <div class="body-greeting">
            Bonjour ${values.name}
          </div>
          Votre paiement a été effectué avec succès
        </div>
        
        <div class="body-table">
          <table>
            
            <tr>
              <td>Mode de paiment</td>
              <td>CIB/ edahabia</td>
            </tr>
            <tr>
              <td>Montant</td>
              <td>${values.price}</td>
            </tr>
            <tr>
              <td>Date de transaction</td>
              <td>${values.newTime}</td>
            </tr>
            <tr>
              <td>N° de commande</td>
              <td>${values.id}</td>
            </tr>
            <tr>
              <td>L'identifiant de la transaction</td>
              <td>${values.orderid}</td>
            </tr>
            <tr>
              <td>Le numéro d'autorisation de la transaction</td>
              <td>${values.aproval}</td>
            </tr>
            <tr>
              <td>Nom et Prénom</td>
              <td>${values.name}</td>
            </tr>

            <tr>
              <td>Date de naissance</td>
              <td>${values.birthday}</td>
            </tr>
            <tr>
              <td>Identifiant Campus France</td>
              <td>${values.number}</td>
            </tr>
            <tr>
              <td>Numéro de téléphone</td>
              <td>${values.phone}</td>
            </tr>
            <tr>
              <td>Espace Campus France de rattachement</td>
              <td>${values.wilaya}</td>
            </tr>

          </table>
        </div>

      </div>

    </div>
  </body>`,
      
      
    }
    if(window.Email) {
      window.Email.send(config).then(message=>alert('message envoyé'))
     
 }
 onClose()
}

  // email invoice
  const handleEmailInvoice = (values,mail)=>{
    const config={
      SecureToken: 'b68c6b3e-451b-4147-b2a9-a7eb1d9be99d',
      To: mail,
      From: "institutfrancais.paiement@gmail.com",
      Subject: "Facture de paiement -l'institut Français d'Algérie-",
      Body: `
      <head> \
      <style>
  .invoice-box {
    max-width: 800px;
    margin: auto;
    padding: 30px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    font-size: 16px;
    line-height: 24px;
    font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    color: #555;
  }

  .invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
  }

  .invoice-box table td {
    padding: 5px;
    vertical-align: top;
  }

  .invoice-box table tr td:nth-child(2) {
    text-align: right;
  }

  .invoice-box table tr.top table td {
    padding-bottom: 20px;
  }

  .invoice-box table tr.top table td.title {
    font-size: 45px;
    line-height: 45px;
    color: #333;
  }

  .invoice-box table tr.information table td {
    padding-bottom: 40px;
  }

  .invoice-box table tr.heading td {
    background: #eee;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
  }

  .invoice-box table tr.details td {
    padding-bottom: 20px;
  }

  .invoice-box table tr.item td {
    border-bottom: 1px solid #eee;
  }

  .invoice-box table tr.item.last td {
    border-bottom: none;
  }

  .invoice-box table tr.total td:nth-child(2) {
    border-top: 2px solid #eee;
    font-weight: bold;
  }

  

  /** RTL **/
  .invoice-box.rtl {
    direction: rtl;
    font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  }

  .invoice-box.rtl table {
    text-align: right;
  }

  .invoice-box.rtl table tr td:nth-child(2) {
    text-align: left;
  }
  .logo{
    width:"30px";
  }
</style>
  </head>
  <body>
  <p>Votre paiement à été effectué avec succès, veuillez trouver ci-dessous votre facture</p>
  <div class="invoice-box">
      <table cellpadding="0" cellspacing="0">
          <tr class="top">
              <td colspan="2">
                  <table>
                      <tr>
                      <td class='title'>
                            <!--<img src="https://www.if-algerie.com/site-Plone.png" alt="l'institut français d'Algérie" class='logo'/>-->
                            l'institut français d'Algérie
                        </td>

                          <td>
                              Facture N° #: <span>${values.id}</span><br />
                              ${values.newTime}
                          </td>
                      </tr>
                  </table>
              </td>
          </tr>

          <tr class="information">
              <td colspan="2">
                  <table>
                      <tr>
                          

                      <td>
                      ${values.name} <br />
                      ${values.wilaya}
                        </td>
                      </tr>
                  </table>
              </td>
          </tr>

          <tr class='heading'>
                <td>Méthode de paiment</td
                <td>CIB/ edahabia</td>
            </tr>

            <tr class='details'>
                <td>Tarif</td>

                <td>${values.price} DZD</td>
            </tr>

      </table>
  </div>
</body>`,
      
      
    }
    if(window.Email) {
      window.Email.send(config).then(message=>alert('message envoyé'))
     
 }
 onClose()
  }
  
  // email validation
  const validateEmail = (email)=>{
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if(!email){
      setEmailError('Ce champ est obligatoire')
    }else if(!emailRegex.test(email)){
      setEmailError("L' adresse email n'est pas valide")
    } else if(type ==="recu"){
        handleEmail(values,email)
    } else if (type === "facture"){
        handleEmailInvoice(values,email)
    }
  }

  // Submit
  const handleSubmit =(e)=>{
    e.preventDefault()
    validateEmail(email)
  }

  return (
    <div>
        <Card className='px-3 py-5 rounded-0'>
          <form onSubmit={handleSubmit}>
            <p className=' text-start '>Entrez l'adresse email</p>
            <div className='d-flex flex-column align-items-start gap-3'>
                    <input type='email' className='p-1 w-100' onChange={handleChange} value={email}/>
                    {emailError && <small className='my-1 text-danger'>{emailError}</small>}
                    <Button className='rounded-0 w-100' variant="primary" type="submit"  >Envoyer</Button>
                    
            </div>
          </form>
        </Card>
    </div>
    
  )
}

export default ConfirmationPopUp