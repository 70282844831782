import React from 'react'
import campus from "../../img/capmus.jpg"
import alumni from "../../img/alumni.jpg"
import bourse from "../../img/bourses-img.jpg"
import {Link} from "react-router-dom"

import styles from "./FranceDropdown.module.css"
import { Card } from 'react-bootstrap'

const bgStyle =(img)=>{
  return {                
    backgroundImage:`url(${img})`,
    backgroundSize:"Cover",
    backgroundPosition:"Center",
    width:'130px',
    height:'130px'
    }
}



function FranceDropdownMenu({className}) {
  return (
    <div className={`${styles.menu} position-absolute `} style={{padding:"1rem",position:"absolute",top:"3rem",backgroundColor:'rgb(0, 146, 213)'}}>
          <div style={{width:"500px"}} className="mb-3" >
            <h6 className='text-start mb-3'>En France</h6>
            <div className="d-flex align-items-center gap-3">
              <Card className="bg-transparent ">
                  <Link to='etudier-en-france/campus-france'>
                    <div style={bgStyle(campus)}>
                    </div>
                    <span className='text-white p-2' >CompusFrance</span>
                  </Link>
              </Card>
              <Card className="bg-transparent ">
                <Link to='etudier-en-france/france-alumni'>
                    <div  style={bgStyle(alumni)}>
                    </div>
                    <span className='text-white  '>France Alumni</span>
                  </Link>
              </Card>
              <Card className="bg-transparent ">
                  <Link to='etudier-en-france/bourses'>
                    <div style={bgStyle(bourse)}>
                    </div>
                    <span className='text-white ' >Bourses</span>
                  </Link>
                
              </Card>

            </div>
          </div>
          <div className='text-start'>
            <Link to="etudier-en-france/en-algerie" className='text-white ' ><h6>En Algérie</h6></Link>
          </div>
    </div>
  )
}

export default FranceDropdownMenu