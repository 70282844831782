import React from 'react'
import { Container } from 'react-bootstrap'
import BgShape from 'components/General/BgShape'
import campus from "../../img/campus-png.png"


function CampusInscriptionPage() {

    // links 
    const siteWebMarchand = "http://phortail.org/club-informatique/definition-web-25.html"
    const biens = "http://fr.wikipedia.org/wiki/Bien_%28%C3%A9conomie%29"
    const services = "http://fr.wikipedia.org/wiki/Services_%28%C3%A9conomie%29"
    const reseaux = "http://fr.wikipedia.org/wiki/R%C3%A9seau_informatique"
    const internet = "http://fr.wikipedia.org/wiki/Internet"

  return (
    <div
      style={{ backgroundColor: "whitesmoke", zIndex: "1" }}
      className=" position-relative overflow-hidden"
    >
      <BgShape type="yellow" right="-80px" top="-50px" rotate="-140deg" />
      <BgShape type="blue" right="-50px" top="750px" rotate="-150deg" />
      <BgShape type="red" left="-80px" top="500px" rotate="140deg" />
      <Container>
        <div className="d-flex flex-column-reverse flex-md-row justify-content-between align-items-center  ms-md-5 me-md-5 mt-5">
          <h1
            className="text-center mt-5"
            style={{ color: "rgb(0, 146, 213)", fontWeight: "bold" }}
          >
            Conditions d'utilisation
          </h1>
          <img src={campus} className="" style={{ width: "150px" }} />
        </div>
        <div className="ms-md-5 me-md-5 mx-auto my-4 text-start bg-white p-4">
          <p style={{ fontSize: "16px" }} className="mb-4">
            <span className="fw-bold">Carte bancaire : </span> désigne tout
            support physique doté d'une sérigraphie et d'une piste magnétique et
            d'un microprocesseur, indépendamment du fabricant, du
            personnalisateur et de la banque émettrice.
            <br />- Les cartes <span className="fw-bold">CIB</span> désignent
            les cartes interbancaires nationales.
          </p>

          <p style={{ fontSize: "16px" }} className="mb-4">
            <span className="fw-bold">CVV2, CVC2 (Cryptogramme visuel) : </span>{" "}
            Les trois derniers chiffres numériques imprimés sur le verso de la
            carte bancaire et servant à son authentification lors d’un paiement
            sur internet.
          </p>

          <p style={{ fontSize: "16px" }} className="mb-4">
            <span className="fw-bold">Demande d'autorisation : </span>{" "}
            vérification auprès des banques de la validité de la carte du
            porteur. Cette demande consiste à vérifier que la carte est bien un
            moyen de paiement valide et qu'elle ne fait pas l'objet d'une mise
            en opposition{" "}
            <span className="fw-bold">
              et que son porteur a les fonds suffisants pour opérer la
              transaction.
            </span>
          </p>

          <p style={{ fontSize: "16px" }} className="mb-4">
            <span className="fw-bold">3-D Secure : </span> Programme (Visa et
            Mastercard) d'authentification tripartite (émetteur / réseau
            /acquéreur) de l'internaute, qui s'identifie en ligne avec un mot de
            passe pendant la phase de paiement. Le commerçant adhérant à ce
            programme, via sa banque, bénéficie d'une protection contre certains
            impayés.
          </p>

          <p style={{ fontSize: "16px" }} className="mb-4">
            <span className="fw-bold">Hébergeur : </span> Société de services
            hébergeant un ou plusieurs sites Internet sur ses propres serveurs,
            connectés à l'Internet.
          </p>

          <p style={{ fontSize: "16px" }} className="mb-4">
            <span className="fw-bold">Paiement Sécurisé : </span>Paiement
            électronique par carte bancaire, se faisant sous un protocole
            d'échange chiffré SSL (Secure Socket Layer) et permettant à
            l'acheteur d'effectuer sa transaction bancaire avec le serveur de la
            banque sans transiter par le  site web marchand
            . Les transactions effectuées sur le réseau Internet sont protégées
            contre l’interception non autorisées et également contre les
            modifications et altérations non autorisées du contenu original des
            messages
          </p>

          <p style={{ fontSize: "16px" }} className="mb-4">
            <span className="fw-bold">Reçu électronique : </span>désigne un
            document électronique délivré automatiquement à l'issue de chaque
            opération de paiement par carte sur internet et comportant une série
            d'indications relatives à la transaction.
          </p>

          <p style={{ fontSize: "16px" }} className="mb-4">
            <span className="fw-bold">Remise : </span> Opération d'envoi en
            banque de transactions, signifiant le crédit/débit du compte du web
            marchand et le débit/crédit du compte
            <span className="fw-bold"> du web acheteur</span>.
          </p>

          <p style={{ fontSize: "16px" }} className="mb-4">
            <span className="fw-bold">S.S.L. (Secure Socket Layer) : </span>
            norme internationale qui permet de procéder à des transferts de
            données sur Internet d’une manière cryptée. Ce protocole de
            transfert ne permet pas d’authentifier le Web acheteur qui émet un
            ordre de paiement.
          </p>

          <p style={{ fontSize: "16px" }} className="mb-4">
            <span className="fw-bold">Web acheteur : </span> Client du Web
            marchand sur Internet. Le web acheteur désigne toute personne
            physique ou morale ayant souscrit un contrat porteur avec une banque
            émettrice de cartes, débouchant sur l'attribution d'une carte
            bancaire de paiement et de retrait. Dans le cadre du paiement en
            ligne le porteur est un acheteur qui utilise sa carte pour effectuer
            le paiement d’un bien ou d’un service sur internet
          </p>

          <p style={{ fontSize: "16px" }} className="mb-4">
            <span className="fw-bold">Web marchand : </span> Commerçant de droit
            algérien tenant une boutique sur Internet. Le Web marchand bénéficie
            du service de paiement sécurisé par Internet.
          </p>

          <p style={{ fontSize: "16px" }} className="mb-4">
            <span className="fw-bold"> Vente en ligne ou e-commerce : </span>
            désigne l'échange de{" "}
            
              biens
           
            et de{" "}
            
              services
            
            entre deux entités sur les{" "}
           
              réseaux informatiques
            
            , notamment{" "}
            
              Internet
           
            .
          </p>
        </div>
      </Container>
      {/* </paymentContext.Provider> */}
    </div>
  );
}

export default CampusInscriptionPage