import React from 'react'
import styles from "../campus.module.css"
const InlineDotsLoader = ({className,style}) => {
  return (
    <div className={`${className} ${styles["scaling-dots"]}`} style={style}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default InlineDotsLoader