import React from 'react'
import { Link } from 'react-router-dom'
import styles from "./menuItemsList.module.css"

function DropdownCard({url,title,img}) {

  const imgStyle={
    width:"150px",
    height:"100px",
    objectFit:"contain",
    overflow:"hidden"
  }
  return (
    <Link to={url} style={{backgroundColor:"rgb(4, 132, 190)"}}>
        <div style={imgStyle}>
            <img src={img} className={styles.cardImg}/>
        </div>
        <div className='py-1' >
            <span className='fw-bold fs-6'>
              {title}
            </span>
        </div>
    </Link>
  )
}

export default DropdownCard