import React from 'react'
import satim from "../../../img/satim-number.png"
import styles from "../campus.module.css"

function SatimCard() {
  return (
    <div className=' p-4 text-center mt-3' style={{backgroundColor:"whitesmoke"}}>
      <h5 className='text-center mt-2'> En cas de problème de paiment appeler le N° vert <span className='text-danger'> de la Satim</span></h5>
      <div>
          <img src={satim} alt="numéro vert de la Satim" style={{width:"200px"}}/>
      </div>
    </div>
    // <div className={`p-3 text-center mt-3 mt-md-0 ${styles.satim}`}  style={{backgroundColor:"whitesmoke",height:"100%"}}>
    //   <h5 className='text-center mb-3'> En cas de problème de paiment appeler le N° vert <span className='text-danger'> de la Satim</span></h5>
    //   <div className='mt-4'>
    //       <img src={satim} alt="numéro vert de la Satim"  style={{width:"236px"}}/>
    //   </div>
    // </div>
  )
}

export default SatimCard