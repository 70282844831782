import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/page/LandingPage";
import MyGallery from "./pages/page/GalleryPage";
import Details from "./pages/blog/Detail";
import MediathequeIndex from "./pages/page/MediathequeIndex";
import RegionMediatheque from "./pages/mediatheques/mediatheque-region";
import Institut from "./pages/Institut/Institut";
import MissionOne from "pages/Institut/MissionOne";
import MissionTwo from "pages/Institut/MissionTwo"
import TestsEtExamens from "pages/Tests Et Examens/TestsEtExamens";
import TestPage from "pages/Tests Et Examens/TestPage";
import AlumniFrance from "pages/Etudes/FranceAlumni";
import Bourses from "pages/Etudes/Bourses";
import EtudierEnAlgerie from "pages/Etudes/EtudierEnAlgerie";
import CampusFrance from "pages/Etudes/CampusFrance";
import Alger from "pages/Cities/Alger";
import AgendaCulturel from "pages/Agenda pages/AgendaCulturel";
import CoursDeFrançaisAlger from "pages/Cours de français/CoursDeFrançaisAlger";
import DetailCours from "pages/Cours de français/DetailCours";
import DetailCours16 from "pages/Cours de français/DetailCours16";
import CoursDeFrançaisGeneral from "pages/Cours de français/CoursDeFrançaisGeneral";
import CityHomePage from "pages/Cities/CityHomePage"
import Layout from "components/General/Layout";
import AgendaCulturelAlger from "pages/Agenda pages/AgendaCulturelAlger";
import EtudierEnFrance from "pages/Etudes/EtudierEnFrance";
import MediathequeCity from "pages/mediatheques/MediathequeCity";
import Inscription from "pages/Inscription/Inscription";
import InstitutPresentationPage from "pages/Institut/InstitutPresentationPage";
import InstitutEquipePage from "pages/Institut/InstitutEquipePage";
import InstitutMissionsPage from "pages/Institut/InstitutMissionsPage"
import TCFpage from "pages/Tests Et Examens/TCFpage";
import DelfDalfPage from "pages/Tests Et Examens/DelfDalfPage";
import AttestationsEtDiplomesPage from "pages/Tests Et Examens/AttestationsEtDiplomesPage";
import TestsPresentationPage from "pages/Tests Et Examens/TestsPresentationPage";
import FAQpage from "pages/Tests Et Examens/FAQpage";
import CoursPresentationPage from "pages/Cours de français/CoursPresentationPage";
import CoursInscriptionPage from "pages/Cours de français/CoursInscriptionPage";
import CoursCalendrierPage from "pages/Cours de français/CoursCalendrierPage";
import LiadPage from "pages/Etudes/LiadPage";
import PehPage from "pages/Etudes/PehPage";
import EssaPage from "pages/Etudes/EsaaPage";
import MediathequePresentationPage from "pages/mediatheques/MediathequePresentationPage";
import MediathequeInscriptionPage from "pages/mediatheques/MediathequeInscriptionPage";
import MediathequeServicesPage from "pages/mediatheques/MediathequeServicePage";
import MediathequeCulturethequePage from "pages/mediatheques/MediathequeCulturethequePage";
import MediathequeAteliersPage from "pages/mediatheques/MediathequeAteliersPage";
import AgendaPage from "pages/Agenda pages/AgendaPage"
import InstitutVillePresentationPage from "pages/Institut/InstitutVillePresentationPage";
import InstitutVilleEquipePage from "pages/Institut/InstitutVilleEquipePage";
import MissionFour from "pages/Institut/MissionFour";
import MissionThree from "pages/Institut/MissionThree";
import EventPage from "pages/Agenda pages/EventPage";
import CampusInscriptionPage from "pages/Inscription Campus France/CampusInscriptionPage";
import SuccessfulPaymentPage from "pages/Inscription Campus France/SuccessfulPaymentPage";
import FailedPaymentPage from "pages/Inscription Campus France/FailedPaymentPage";
import InvoicePage from "pages/Inscription Campus France/InvoicePage";
import ActionsCulturellesPage from "pages/Agenda pages/ActionsCulturellesPage";
import ConditionsPage from "pages/Inscription Campus France/ConditionsPage"
// import Contexts 
import PaymentProvider from "./contexts/payment-Context/PaymentContext"
import CampusProccessingPage from "pages/Inscription Campus France/CampusProccessingPage";
import SuccessPage from "pages/Inscription Campus France/SuccessPage";
import firebase from "./firebase";
function App() {
  firebase.automaticDataCollectionEnabled=true;
  return (
    <div className="App">
      <BrowserRouter>
        {" "}
        {/* <Switch>
          <Route path="/region" component={RegionMediatheque} />
          <Route path="/details" component={Details} />
          <Route path="/gallery" component={MyGallery} />
          <Route path="/media" component={MediathequeIndex} />
          <Route path="/mediatheque" component={MediathequeIndex} />
          <Route path="/institut" component={Institut} />
          <Route path="/mission" component={Mission} />
          <Route path="/examens" component={TestsEtExamens} />
          <Route path="/tests/:id" component={TestPage} />
          <Route path="/etudier-en-france/alumni" component={AlumniFrance} />
          <Route path="/etudier-en-france/bourses" component={Bourses} />
          <Route path="/etudier-en-france/en-algerie" component={EtudierEnAlgerie} />
          <Route path="/etudier-en-france/campus-france" component={CampusFrance} />
          <Route path="/alger" component={Alger} />
          <Route path="/agenda-culturel" component={AgendaCulturel} />

          <Route path="/cours-de-français/alger" component={CoursDeFrançaisAlger} />
          <Route path="/cours-de-français/alger-elf-11-15-ans" component={DetailCours} />
          <Route path="/cours-de-français/alger-elf-16-ans" component={DetailCours16} />

          <Route path="/" component={LandingPage} />
          
          
        </Switch> */}
        <PaymentProvider>
        <Routes>
          <Route path="/" element={<Layout home='/'/>}>
              <Route index element={<CampusInscriptionPage/>}/>
              <Route path="succes" >
                  {/* <Route index  element={<SuccessfulPaymentPage/>}/> */}
                  <Route index  element={<SuccessPage/>}/>
                  <Route path="facture" element={<InvoicePage/>}/>
                </Route>
              <Route path="echec" element={<FailedPaymentPage/>}/>
              <Route path = "processing" element={<CampusProccessingPage/>}/>
              <Route path="conditions-d-utilisation" element={<ConditionsPage/>}/>

              {/* <Route path="institut" >
                <Route index element={<InstitutPresentationPage ville="algerie"/>}/>
                <Route path="presentation" element={<InstitutPresentationPage/>} />
                <Route path="equipe" element={<InstitutEquipePage/>}/>
                <Route path="nos-missions" >
                  <Route index element={<InstitutMissionsPage/>} />
                  <Route path="Mission-de-cooperation-educative-et-linguistique" element={<MissionOne/>}/>
                  <Route path="Mission-de-cooperation-institutionnelle" element={<MissionTwo/>}/>
                  <Route path="Mission-de-cooperation-culturelle" element={<MissionThree/>}/>
                  <Route path="Mission-de-cooperation-universitaire" element={<MissionFour/>}/>
                </Route>
              </Route>
              <Route path="tests-et-examens" >
                  <Route index element={<TestsEtExamens/>}/>
                  <Route path="presentation">
                    <Route index element={< TestsPresentationPage />} />
                    <Route path="tcf">
                        <Route index element={<TCFpage/>} />
                        <Route path=":id" element={<TestPage/>} />
                    </Route>
                    <Route path="delf-et-dalf" element={<DelfDalfPage/>} />
                  </Route>
                  <Route path="tcf">
                      <Route index element={<TCFpage/>} />
                      <Route path=":id" element={<TestPage/>} />
                  </Route>
                  <Route path="delf-et-dalf" element={<DelfDalfPage/>} />
                  <Route path="attestations-et-diplomes" element={<AttestationsEtDiplomesPage/>} />
                  <Route path="faq" element={<FAQpage/>} />
              </Route>
              <Route path="etudes"  >
                <Route index element={<EtudierEnFrance/>}/>
                <Route path="en-france"  >
                    <Route path="campus-france" >
                      <Route index element={<CampusFrance region ="alger"/>}/>
                      <Route path=":id" element={<EventPage/>} />
                    </Route>
                    <Route path="france-alumni">
                      <Route index element={<AlumniFrance/>}/>
                      <Route path=":id" element={<EventPage/>}/>
                    </Route>
                    <Route path="bourses" element={<Bourses/>} />
                </Route>
                
                <Route path="en-algerie">
                    <Route index element={<EtudierEnAlgerie/>} />
                    <Route path="liad" >
                      <Route index element={<LiadPage/>}/>
                      <Route path=":id" element={<EventPage/>}/>
                    </Route>
                    <Route path="peh" >
                      <Route index element={<PehPage/>}/>
                      <Route path=":id" element={<EventPage/>}/>
                    </Route>
                    <Route path="essa" >
                      <Route index element={<EssaPage/>}/>
                      <Route path=":id" element={<EventPage/>}/>
                    </Route>
                </Route>
              </Route>
              <Route path="mediatheque" >
                <Route index element={<MediathequeIndex/>} />
                <Route path="presentation" element={<MediathequeIndex/>}/>
                <Route path="inscription" element={<MediathequeIndex/>}/>
                <Route path="ateliers" element={<MediathequeIndex/>}/>
                <Route path="culturetheque" element={<MediathequeIndex/>}/>
              </Route> */}

              {/* <Route path="cours-de-francais" >
                <Route index element={<CoursDeFrançaisGeneral/>}/>
                <Route path="presentation"  >
                  <Route index element={<CoursPresentationPage/>}/>
                  <Route path=":id" element={<DetailCours region="national"/>}/>
                </Route> */}
                {/* <Route path="inscription" element={<CoursInscriptionPage/>} /> */}
                {/* <Route path="calendrier-des-sessions" element={<CoursCalendrierPage/>} />     */}
              {/* </Route> */}
              {/* <Route path="culture"  >
                  <Route index element={<AgendaPage/>}/>
                  <Route path="agenda" >
                      <Route index element={<AgendaPage/>}/>
                      <Route path="musique" >
                        <Route index element={<AgendaPage type="musique"/>}/>
                        <Route path=":id" element={<EventPage/>}/>
                      </Route>    
                      <Route path="theatre" >
                        <Route index element={<AgendaPage type="théatre"/>}/>
                        <Route path=":id" element={<EventPage/>}/>
                      </Route>    
                      <Route path="danse" >
                        <Route index element={<AgendaPage type="danse"/>}/>
                        <Route path=":id" element={<EventPage/>}/>
                      </Route>    
                      <Route path="expo" >
                        <Route index element={<AgendaPage type="expo"/>}/>
                        <Route path=":id" element={<EventPage/>}/>
                      </Route>    
                      <Route path="cinema" >
                        <Route index element={<AgendaPage type="cinéma"/>}/>
                        <Route path=":id" element={<EventPage/>}/>
                      </Route>    
                      <Route path="conferences-et-rencontres" >
                        <Route index element={<AgendaPage type="conférences et rencontres"/>}/>
                        <Route path=":id" element={<EventPage/>}/>
                      </Route>    
                      <Route path="jeunesse" >
                        <Route index element={<AgendaPage type="jeunesse"/>}/>
                      </Route>    
                  </Route>  
                  <Route path="nos-actions-culturelles" element={<ActionsCulturellesPage/>}/>
              </Route>
              <Route path="event" element={<EventPage/>}/>
              <Route path="paiment" >
                <Route index element={<CampusInscriptionPage/>}/>
                <Route path="success" >
                  <Route index  element={<SuccessfulPaymentPage/>}/>
                  <Route path="facture" element={<InvoicePage/>}/>
                </Route>
                <Route path="failure" element={<FailedPaymentPage/>}/>
              </Route> */}
          </Route>

{/* ======= ALGER ======== */}

          {/* <Route path="/alger" element={<Layout home='/alger'/>} >
              <Route index element={< CityHomePage />}/>
              <Route path=":id" element={<EventPage/>} />
             
              <Route path="institut" >
                  <Route index element={< InstitutVillePresentationPage />} />
                  <Route path="presentation" element={< InstitutVillePresentationPage ville="alger" />} />
                  <Route path="equipe" element={< InstitutVilleEquipePage/>} />
                  <Route path="nos-missions" element={<InstitutMissionsPage/>}/>
              </Route>

              <Route path="tests-et-examens" >
                  <Route index element={<TestsEtExamens/>}/>
                  <Route path="presentation">
                    <Route index element={< TestsPresentationPage />} />
                    <Route path="tcf">
                        <Route index element={<TCFpage/>} />
                        <Route path="tcf/:id" element={<TestPage/>} />
                    </Route>
                    <Route path="delf-et-dalf" element={<DelfDalfPage/>} />
                  </Route>
                  <Route path="tcf">
                      <Route index element={<TCFpage/>} />
                      <Route path=":id" element={<TestPage/>} />
                  </Route>
                  <Route path="delf-et-dalf" element={<DelfDalfPage/>} />
                  <Route path="attestations-et-diplomes" element={<AttestationsEtDiplomesPage/>} />
                  <Route path="faq" element={<FAQpage/>} />
              </Route>

              <Route path="cours-de-francais"  >
                  <Route index element={<CoursDeFrançaisAlger/>} />
                  <Route path="presentation"  >
                  <Route index element={<CoursPresentationPage/>}/>
                  <Route path=":id" element={<DetailCours region="alger"/>}/>
                </Route>
                  <Route path="inscription" element={<CoursInscriptionPage/>} />
                  <Route path="calendrier-des-sessions" element={<CoursCalendrierPage/>} />
              </Route> 
              <Route path="etudes"  >
              <Route index element={<EtudierEnFrance/>}/>
              <Route path="en-france"  >
                    <Route path="campus-france" >
                      <Route index element={<CampusFrance region ="alger"/>}/>
                      <Route path=":id" element={<EventPage/>} />
                    </Route>
                    <Route path="france-alumni">
                      <Route index element={<AlumniFrance/>}/>
                      <Route path=":id" element={<EventPage/>}/>
                    </Route>
                    <Route path="bourses" element={<Bourses/>} />
                </Route>
                
                <Route path="en-algerie">
                    <Route index element={<EtudierEnAlgerie/>} />
                    <Route path="liad" >
                      <Route index element={<LiadPage/>}/>
                      <Route path=":id" element={<EventPage/>}/>
                    </Route>
                    <Route path="peh" >
                      <Route index element={<PehPage/>}/>
                      <Route path=":id" element={<EventPage/>}/>
                    </Route>
                    <Route path="essa" >
                      <Route index element={<EssaPage/>}/>
                      <Route path=":id" element={<EventPage/>}/>
                    </Route>
                </Route>
              </Route>
              
                <Route path="agenda-culturel" element={<AgendaCulturelAlger/>} />
                <Route path="culture"  >
                  <Route index element={<AgendaPage/>}/>
                  <Route path="agenda" >
                      <Route index element={<AgendaPage/>}/>
                      <Route path="musique" >
                        <Route index element={<AgendaPage city="alger" type="musique"/>}/>
                        <Route path=":id" element={<EventPage/>}/>
                      </Route>    
                      <Route path="theatre" >
                        <Route index element={<AgendaPage city="alger" type="théatre"/>}/>
                        <Route path=":id" element={<EventPage/>}/>
                      </Route>    
                      <Route path="danse" >
                        <Route index element={<AgendaPage city="alger" type="danse"/>}/>
                        <Route path=":id" element={<EventPage/>}/>
                      </Route>    
                      <Route path="expo" >
                        <Route index element={<AgendaPage city="alger" type="expo"/>}/>
                        <Route path=":id" element={<EventPage/>}/>
                      </Route>    
                      <Route path="cinema" >
                        <Route index element={<AgendaPage city="alger" type="cinéma"/>}/>
                        <Route path=":id" element={<EventPage/>}/>
                      </Route>    
                      <Route path="conferences-et-rencontres" >
                        <Route index element={<AgendaPage city="alger" type="conférences et rencontres"/>}/>
                        <Route path=":id" element={<EventPage/>}/>
                      </Route>    
                      <Route path="jeunesse" >
                        <Route index element={<AgendaPage city="alger" type="jeunesse"/>}/>
                      </Route>    
                  </Route>  
                  <Route path="nos-actions-culturelles" element={<ActionsCulturellesPage/>}/> 
              </Route>
                <Route path="mediatheque" city="alger" >
                    <Route index element={<MediathequePresentationPage/>}/>
                    <Route path="presentation" element={<MediathequePresentationPage/>}/>
                    <Route path="inscription" element={<MediathequeInscriptionPage/>}/>
                    <Route path="ateliers" element={<MediathequeAteliersPage/>}/>
                    <Route path="culturetheque" element={<MediathequeCulturethequePage/>}/>
                    <Route path="espace-jeunesse" element={<MediathequeAteliersPage/>}/>
                </Route>
                
          </Route> */}

{/* ======= ORAN ======== */}

          {/* <Route path="/oran" element={<Layout home='/alger'/>} >
              <Route index element={< CityHomePage />} />
              <Route path="institut" element={<Alger/>} />

              <Route path="tests-et-examens" >
                  <Route index element={<TestsEtExamens/>}/>
                  <Route path="inscription" element={<Inscription/>} />
                  <Route >
                    <Route path=":id" element={<TestPage/>} />
                  </Route>
              </Route>
              <Route path="cours-de-francais"  >
                  <Route index element={<CoursDeFrançaisAlger/>} />
                  <Route path="cours-11" element={<DetailCours/>} />
                  <Route path="cours-16" element={<DetailCours16/>} />
              </Route> 
              <Route path="etudier-en-france"  >
                <Route index element={<AlumniFrance/>}/>
                <Route path="campus-france" element={<CampusFrance/>} />
                <Route path="france-alumni" element={<AlumniFrance/>} />
                <Route path="bourses" element={<Bourses/>} />
                <Route path="en-algerie" element={<EtudierEnAlgerie/>} />
              </Route>
              
                <Route path="agenda-culturel" element={<AgendaCulturelAlger/>} />
                <Route path="mediatheque" element={<MediathequeCity city="oran"/>} />
                
          </Route> */}

{/* ======= CONSTANTINE ======== */}
          
          {/* <Route path="/constantine" element={<Layout home='/alger'/>} >
              <Route index element={< CityHomePage />} />
              <Route path="institut" element={<Alger/>} />

              <Route path="tests-et-examens" >
                  <Route index element={<TestsEtExamens/>}/>
                  <Route path="inscription" element={<Inscription/>} />
                  <Route >
                    <Route path=":id" element={<TestPage/>} />
                  </Route>
              </Route>
              <Route path="cours-de-francais"  >
                  <Route index element={<CoursDeFrançaisAlger/>} />
                  <Route path="cours-11" element={<DetailCours/>} />
                  <Route path="cours-16" element={<DetailCours16/>} />
              </Route> 
              <Route path="etudier-en-france"  >
                <Route index element={<AlumniFrance/>}/>
                <Route path="campus-france" element={<CampusFrance/>} />
                <Route path="france-alumni" element={<AlumniFrance/>} />
                <Route path="bourses" element={<Bourses/>} />
                <Route path="en-algerie" element={<EtudierEnAlgerie/>} />
              </Route>
              
                <Route path="agenda-culturel" element={<AgendaCulturelAlger/>} />
                <Route path="mediatheque" element={<MediathequeCity city="constantine"/>} />
                
          </Route> */}

{/* ======= ANNABA ======== */}

          {/* <Route path="/annaba" element={<Layout home='/alger'/>} >
              <Route index element={< CityHomePage />} />
              <Route path="institut" element={<Alger/>} />

              <Route path="tests-et-examens" >
                  <Route index element={<TestsEtExamens/>}/>
                  <Route path="inscription" element={<Inscription/>} />
                  <Route >
                    <Route path=":id" element={<TestPage/>} />
                  </Route>
              </Route>
              <Route path="cours-de-francais"  >
                  <Route index element={<CoursDeFrançaisAlger/>} />
                  <Route path="cours-11" element={<DetailCours/>} />
                  <Route path="cours-16" element={<DetailCours16/>} />
              </Route> 
              <Route path="etudier-en-france"  >
                <Route index element={<AlumniFrance/>}/>
                <Route path="campus-france" element={<CampusFrance/>} />
                <Route path="france-alumni" element={<AlumniFrance/>} />
                <Route path="bourses" element={<Bourses/>} />
                <Route path="en-algerie" element={<EtudierEnAlgerie/>} />
              </Route>
              
                <Route path="agenda-culturel" element={<AgendaCulturelAlger/>} />
                <Route path="mediatheque" element={<MediathequeCity city="annaba"/>} />
                
          </Route> */}

{/* ======= TLEMCEN ======== */}

          {/* <Route path="/tlemcen" element={<Layout home='/alger'/>} >
              <Route index element={< CityHomePage />} />
              <Route path="institut" element={<Alger/>} />

              <Route path="tests-et-examens" >
                  <Route index element={<TestsEtExamens/>}/>
                  <Route path="inscription" element={<Inscription/>} />
                  <Route >
                    <Route path=":id" element={<TestPage/>} />
                  </Route>
              </Route>
              <Route path="cours-de-francais"  >
                  <Route index element={<CoursDeFrançaisAlger/>} />
                  <Route path="cours-11" element={<DetailCours/>} />
                  <Route path="cours-16" element={<DetailCours16/>} />
              </Route> 
              <Route path="etudier-en-france"  >
                <Route index element={<AlumniFrance/>}/>
                <Route path="campus-france" element={<CampusFrance/>} />
                <Route path="france-alumni" element={<AlumniFrance/>} />
                <Route path="bourses" element={<Bourses/>} />
                <Route path="en-algerie" element={<EtudierEnAlgerie/>} />
              </Route>
              
                <Route path="agenda-culturel" element={<AgendaCulturelAlger/>} />
                <Route path="mediatheque" element={<MediathequeCity city="tlemcen"/>} />
                
          </Route> */}


          {/* <Route path="/region" element={<RegionMediatheque/>} /> */}
          {/* <Route path="/details" element={<Details/>} />
          <Route path="/gallery" element={<MyGallery/>} />
          <Route path="/media" element={<MediathequeIndex/>} /> */}
          {/* <Route path="/mediatheque" element={<MediathequeIndex/>} /> */}
          {/* <Route path="/institut" element={<Institut/>} /> */}
          {/* <Route path="/mission" element={<MissionOne/>} /> */}
          {/* <Route path="/tests-et-examens" element={<TestsEtExamens/>} /> */}
          {/* <Route path="/tests-et-examens/:id" element={<TestPage/>} /> */}
          {/* <Route path="/etudier-en-france/alumni" element={<AlumniFrance/>} />
          <Route path="/etudier-en-france/bourses" element={<Bourses/>} />
          <Route path="/etudier-en-france/en-algerie" element={<EtudierEnAlgerie/>} />
          <Route path="/etudier-en-france/campus-france" element={<CampusFrance/>} /> */}
          {/* <Route path="/alger" element={<Alger/>} /> */}
          {/* <Route path="/cours-de-francais-alger" element={<CoursDeFrançaisAlger/>} /> */}
          {/* <Route path="/cours-11" element={<DetailCours/>} />
          <Route path="/cours-16" element={<DetailCours16/>} /> */}
          {/* <Route path="/" element={<LandingPage/>} /> */}
          {/* <Route path="/mission" component={Mission} /> */}
          
          
        </Routes>
        </PaymentProvider>

      </BrowserRouter>

      {/* <AgendaComponent></AgendaComponent> */}
      {/* <IdentitSComponent></IdentitSComponent> */}
      {/* <IdentitSComponent></IdentitSComponent>
      <AppelProjetsCandidaturesComponent></AppelProjetsCandidaturesComponent>
      <NewsletterLiensComponent></NewsletterLiensComponent>
      <FooterComponent></FooterComponent> */}
      {/* <HeaderComponent></HeaderComponent>
      <SlideComponent></SlideComponent>
      <AgendaComponent></AgendaComponent>
      <IdentitSComponent></IdentitSComponent>
      <AppelProjetsCandidaturesComponent></AppelProjetsCandidaturesComponent>
      <NewsletterLiensComponent></NewsletterLiensComponent>
      <FooterComponent></FooterComponent> */}
    </div>
  );
}

export default App;
