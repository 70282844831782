import React from 'react'
import styles from "./menuItemsList.module.css"
import MenuItem from './MenuItem'
import { useLocation } from 'react-router-dom';
import DropdownCard from './DropdownCard';


function Dropdown({display,submenus,dropdown,depthLevel,title}) {
  // ==== hide some menu item in some pages =====
/*
  const location = useLocation()
  const path = location.pathname
  const villes = ['alger','oran','constantine','tlemcen','annaba']
 
  const contain = villes.some(el=>{
    if(path.includes(el)){
      return true
    }
    return false
  })

  function isContains(items) {
    items.some(el=>{
      if(path.includes(el)){
        return true
      }
      return false
    })
  }
=================================================
*/
const EtudeStyle={
  padding:"1rem",
  display:"flex",
  flexDirection:"row",
  alignItems:"center",
  justifyContent:"space-between",
  backgroundColor:"green",
  width:"200%"
}

    depthLevel = depthLevel + 1;
    const dropdownClass = depthLevel > 1 ? styles.dropdown_submenu : "";
    
    const showStyle = ()=>{
      if (dropdown && display=="horizontal") {
        return styles.show_etudes
      }else if(dropdown){
        return styles.show
      }
    }

    // get all subsubmenu fo "Etudes"
    const etudeSubmenu =(sumbenus)=>{
      const menu = submenus.map((el)=>{
        return el.submenu.map(el=>{
          return el  });
      })
      const allSubmenus = [...menu[0],...menu[1]]
      return allSubmenus
      

    }

  return (
    // display!=="horizontal"?
    title!=="etudes"?
    <ul className={`${styles.dropdown} ${dropdownClass} ${dropdown&&styles.show}`}>
      {submenus.map((submenu, index) => {
        // if(submenu.hide){
        //   return !isContains(submenu.hide) && <MenuItem items={submenu} key={index} depthLevel={depthLevel} className={styles.dropItem} hide={isContains(submenu.hide)} />
        // }
        // return display=="horizontal"?<EtudeDropdown items={submenu.title}/> : <MenuItem items={submenu} key={index} depthLevel={depthLevel} className={styles.dropItem} />
        return  <MenuItem items={submenu} key={index} depthLevel={depthLevel} className={styles.dropItem} />

      }
      )}
    </ul>
    :
    <ul className={`${styles.dropdown_etudes}  ${dropdown&&styles.show}`}>

      {etudeSubmenu().map((submenu, index) => {
        return  <DropdownCard key={index} url={submenu.url} title={submenu.title} img={submenu.img}/>
      }
      )}
    </ul>

  )
}

export default Dropdown