import React ,{useRef} from 'react'
import { useLocation } from 'react-router-dom'
import { useReactToPrint } from "react-to-print";
import styles from "./campus.module.css"
import CoverImg from 'components/General/CoverImg'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import ifa from "../../img/ifa-logo.png"
import { Container,Button, Row, Col } from 'react-bootstrap'
import { RiMailSendLine } from "react-icons/ri";
import { FaPrint } from "react-icons/fa";
import EmailPopUp from './EmailPopUp';
import ModalCmp from 'pages/newsletter/Modal'
import {usePayment} from "../../contexts/payment-Context/PaymentContext"


function InvoicePage({state}) {
    const location = useLocation()
    const componentRef = useRef();
    // === ORIGINAL : receive the data from location.state
    // const {name,surname, prenom,id,time,price,telephone,naissance,email,wilaya,montant} = location.state
    
    const {formValues,setFormValues,formErrors,setFormErrors,isOpen,setIsOpen,payment,setPayment,tarif,setTarif,isChecked,setIsChecked,isRecaptcha,setIsRecaptcha,newSuccessData,setNewSuccessData} = usePayment()
    //==== NEW:  get the data from the state of payment context
    const {name,id,time,price,wilaya} = newSuccessData


    const invoiceValues = {name,id,time,price,wilaya}
    const date = new Date()
    const day = date.getDate()
    const month = date.getMonth()+1
    const year = date.getFullYear()
    const hours = date.getHours()
    const minutes = date.getMinutes()

    // Invoice printing
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle : "Facture Campus France"
      });


    const handleClose =(p)=>{
        setIsOpen(false)
    }
    // Email sending
    const handleEmail = ()=>{
        setIsOpen(true)
    }

  return (
    <>
        <Container>
            <div className={`mt-5 ${styles.invoice_container}`} >
                <div className={styles.invoice_box} ref={componentRef}>
                    <table cellpadding="0" cellspacing="0">
                        <tr className={styles.top}>
                            <td colspan="2">
                                <table>
                                    <tr>
                                        <td className={styles.title}>
                                            <img src={ifa} alt="l'institur français d'Algérie" className='w-50'/>
                                        </td>

                                        <td>
                                            Facture N° #:<span>{id}</span> <br />
                                            {/* {`Date : ${day}/${month}/${year} ${hours}:${minutes}`} */}
                                            {time}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>

                        <tr className={styles.information}>
                            <td colspan="2">
                                <table>
                                    <tr>
                                        {/* <td>
                                            Sparksuite, Inc.<br />
                                            12345 Sunny Road<br />
                                            Sunnyville, CA 12345
                                        </td> */}

                                        <td>
                                            {name} <br />
                                            {/* {email}<br/> */}
                                            {wilaya}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>

                        <tr className={styles.heading}>
                            <td>Méthode de paiment</td>

                            <td>CIB/ edahabia</td>
                        </tr>

                        <tr className={styles.details}>
                            <td>Tarif</td>

                            <td>{price} DZD</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div className=' mx-auto d-flex align-items-center justify-content-center gap-5 py-3 mb-3'>
                <Button className='d-flex align-items-center gap-2 rounded-0 ' onClick={handleEmail}><RiMailSendLine />Envoyer par email</Button>
                <Button className='d-flex align-items-center gap-2 rounded-0' onClick={handlePrint}><FaPrint/>Imprimer</Button>
            </div>
        </Container>
        <ModalCmp open={isOpen} onClose={handleClose} values ={invoiceValues}>
            <EmailPopUp type='facture' onClose={handleClose} values ={invoiceValues}/>
          </ModalCmp>
    </>
    
  )
}

export default InvoicePage