import React,{useContext} from 'react'
import EventCard from './EventCard'
import { Row} from 'react-bootstrap'
import {filterContext} from "../../pages/Agenda pages/AgendaPage"
import img1 from "../../img/event-sila.jpg"
import img2 from "../../img/ComponentTMP_4-image.png";
import img3 from "../../img/event-photographie.jpg";
import img4 from "../../img/ComponentTMP_4-image3.png";
import img5 from "../../img/event-liad.jpeg";
import img6 from "../../img/event-writing.jpg";
import img7 from "../../img/ComponentTMP_4-image2.png";
import pImg1 from "../../img/event-p-stage.jpg"
import pImg2 from "../../img/event-p-socio.jpeg"
import pImg3 from "../../img/event-p-concour.jpg"


const futurEvents =[
    {
        city:"alger",
        date:"01 Octobre 2022",
        title:"L'Institut français participe au Salon International du Livre d'Alger (SILA 2022)  !",
        description:"Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression",
        type:"culturel",
        image:img1,
        tag:"arts de la scène",
        
    },
    {
        city:"alger",
        date:"22 Octobre 2022",
        title:"Ouverture d’un nouvel espace café restaurant",
        description:"Bienvenue aux « saveurs du savoir » qui fait peau neuve et rouvre ses portes au public dimanche 6 février 2022.",
        type:"culturel",
        image:img2,
        tag:"Arts visuels"
    },
    {
        city:"oran",
        date:"29 Octobre 2022",
        title:"Exposition photographique IQBAL/ARRIVEES, pour une jeune photographie algérienne.",
        description:"En proposant un regard croisé sur l'Algérie, cette exposition a pour vocation de révéler au grand public les talents de la jeune photographie algérienne.",
        type:"culturel",
        image:img3,
        tag:"Cinéma"
    }
    ,
    {
        city:"constantine",
        date:"30 Aout 2022",
        title:"« Territoires parallèles » exposition photo de Hamid Rahiche",
        description:"Ce projet photographique voudrait questionner le regard sur ces deux villes emblématiques de...",
        type:"culturel",
        image:img4,
        tag:"Débat d'idées"
    },
    {
        city:"annaba",
        date:"18 Novembre 2022",
        title:"LANCEMENT DU PROJET IMMOBILIER « GRAND LIAD »",
        description:"L’Ambassade de France à Alger est heureuse d’annoncer le lancement officiel du projet immobilier «Grand LIAD»,",
        type:"culturel",
        image:img5,
        tag:"Jeunesse"
    },
    {
        city:"constantine",
        date:"25 décembre 2022",
        title:"LANCEMENT DU PRIX 2022 DE LA NOUVELLE FANTASTIQUE - APPEL À CANDIDATURES",
        description:"L'IFA lance sa quatrième édition du Prix de la Nouvelle Fantastique d'expression française en Algérie. Candidatures ouvertes jusqu'au 25 janvier 2023",
        type:"culturel",
        image:img6,
        tag:"Jeunesse"
    }
    ,
    {
        city:"telemcen",
        date:"15 janvier 2023",
        title:"Exposition « Abd el-Kader » du 15 janvier au 22 février 2023 au Mucem",
        description:"Emir de la résistance, saint combattant, fondateur de l’Etat algérien, précurseur de la codification du droit...",
        type:"divers",
        image:img7,
        tag:"divers"
    }
]

const pastEvents = [
    {
        city:"alger",
        date:"13 mars 2020",
        title:"Stage d’ete pour futurs professeurs de français (étudiants ENS d’Algérie) centre de linguistique appliquee (CLA) – université de franche-comté",
        description:"Dans le cadre de la coopération algéro-française, l'Ambassade de France appuie la formation pédagogique des futurs enseignants de français dans les écoles, ...",
        type:"divers",
        image:pImg1,
        tag:"divers"
    },
    {
        city:"alger",
        date:"25 janvier 2016",
        title:'la sociolinguistique urbaine en algérie : états des lieux et perspectives" colloque en hommage à thierry bulot, sociolinguiste',
        description:"Un an après sa disparition, La France et l'Algérie rendent hommage à Thierry Bulot et ses recherches en organisant un colloque à Alger 2 les 25 et 26 janvier 2016.",
        type:"culturel",
        image:pImg2,
        tag:"Cinéma"
    },
    {
        city:"oran",
        date:"17 juin 2021",
        title:"concours d'écriture - semaine de la langue française en algérie",
        description:"Le Prix International du Jeune Écrivain de Langue Française est un prix annuel destiné à récompenser des oeuvres d’imagination inédites",
        type:"culturel",
        image:pImg3,
        tag:"Arts visuels"
    }

]

const futurAlgerEvents = futurEvents.filter((event)=>{
    return event.city === "alger"
})

const pastAlgerEvents = pastEvents.filter((event)=>{
    return event.city === "alger"
})



function EventsGrid({type}) {
    // import the filter Context from the parent Component
    const {events,setEvents,all,setAll,today,setToday,week,setWeek,month,setMonth,activity,setActivity,region,setRegion} = useContext(filterContext)
    const filtredFuturEvents = futurEvents.filter((event)=>{
        return event.city== region.toLowerCase()
    })


    // const futurEventList = (region ? futurAlgerEvents : futurEvents).map((event,index)=>{
    //     return (<EventCard key={index} event={event} id={index} type={type}/>)
    // })

    // const pastEventList = (region ? pastAlgerEvents : pastEvents).map((event,index)=>{
    //     return (<EventCard key={index} event={event} id={index} type={type}/>)
    // })

    const futurEventList = events.map((event,index)=>{
        return (<EventCard key={index} event={event} id={index} type={type}/>)
    })

    const pastEventList = (pastEvents).map((event,index)=>{
        return (<EventCard key={index} event={event} id={index} type={type}/>)
    })
  return (
    <Row>
        {type==="future"?futurEventList:pastEventList}
    </Row>
  )
}

export default EventsGrid