
import React, { useState } from 'react';
import {Modal,Button,Card} from 'react-bootstrap';
import { BsCheckCircleFill } from "react-icons/bs";
import styles from "./campus.module.css"
import cvv from "../../img/cvv.jpeg"
function ConfirmationPopUp({onClose,tarif}) {
  return (
    <div className={styles.confirmation}>
        <div className= {` px-3 py-4  bg-white`}>
          <h2>Montant à payer : {tarif} DA</h2>
            <p className=' text-center '>Êtes-vous sûr de vouloir continuer ce paiement ?</p>
            <div className='d-flex justify-content-center gap-5 mt-2 mb-4'>
                    <Button className='rounded-0 w-50'variant="primary" onClick={event=>onClose(true)}>Oui</Button>
                    <Button className='rounded-0 w-50'variant="outline-primary" onClick={event=>onClose(false)}>Non</Button>
                </div>
                <div>
                  <hr/>
                  <small className='mt-3 d-block '>Un mail de confirmation avec un reçu de paiement vous sera envoyé à l’issue de cette étape. Vérifiez dans votre boite email votre dossier "spams" au besoin.</small>
                </div>
                <hr/>
                <div className={styles.cvv}>
                <img src={cvv} className="w-100 d-block mx-auto my-2"/>
                  <p className='mt-1'>Le numéro de sécurité CVV2 (Card Verification Value) est un code de sécurité
                    qui a été introduit par les compagnies bancaires afin de lutter contre la fraude
                    à la carte de crédit sur internet. La procédure stipule que le détenteur de
                    la carte entre le code CVV2 lors de la transaction.  Ce cryptogramme visuel désigne
                    en général les trois derniers chiffres présents au dos d'une carte de paiement.
                  </p>
                </div>
                
        </div>
    </div>
    
  )
}

export default ConfirmationPopUp