
import React ,{useRef,useContext,useEffect, useState} from 'react'
import { useReactToPrint } from "react-to-print";
import CoverImg from 'components/General/CoverImg'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import { Container,Button, Row, Col } from 'react-bootstrap'
import { BsCheckCircle } from "react-icons/bs";
import subscription from "../../img/subscription.jpg"
import { HiOutlineDocumentText,HiDownload } from "react-icons/hi";
import { RiMailSendLine } from "react-icons/ri";
import { FaPrint } from "react-icons/fa";
import { Link, useLocation,useSearchParams } from 'react-router-dom';
import ifa from "../../img/ifa-logo.png"
import campus from "../../img/Campus.png"
import CampusInvoice from "./CampusInvoice"
import InvoicePage from './InvoicePage';
import styles from "./campus.module.css"
import {usePayment} from "../../contexts/payment-Context/PaymentContext"
import axios from 'axios'
import SatimCard from './components/SatimCard';
import HelpCallCard from './components/HelpCallCard';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import EmailPopUp from './EmailPopUp';
import ModalCmp from 'pages/newsletter/Modal'
import {handleEmailInvoice} from "./helpers/useInvoiceEmail"
import InlineDotsLoader from './components/InlineDotsLoader';
import InfoItem from './components/InfoItem';


function SuccessPage() {
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams();
    const [succesData,setsuccesData] = useState({})
    const [loading,setLoading] = useState(true)
    const [retry,setRetry] = useState(false)
    const [emailInvoice,setEmailInvoice] = useState(false)

    
  const {formValues,setFormValues,formErrors,setFormErrors,isOpen,setIsOpen,payment,setPayment,tarif,setTarif,isChecked,setIsChecked,isRecaptcha,setIsRecaptcha,newSuccessData,setNewSuccessData} = usePayment()
    
    const mail = "aissam@gmail.com"
    const date = new Date()
    const day = date.getDate()
    const month = date.getMonth()+1
    const year = date.getFullYear()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    
    //====== print invoice
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      // content: () => componentRef.current,
      content: () => { 
      var doc = new jsPDF();
    //   doc.addImage(campus, 'png', 10, 5, 40, 40)
        doc.addImage(ifa, 'png', 10, 15, 40, 20)

      doc.setFontSize(24)
      doc.setTextColor(1, 77, 153)
      doc.setFont("helvetica", "bold")
      doc.text("Reçu de paiement",10, 60)

      doc.setFontSize(14)
      doc.setTextColor(0, 0, 0)


      doc.setFont("helvetica", "bold")
      doc.text("Mode de paiement", 10, 80);
      doc.setFont("courier", "normal")
      doc.text("CIB/ edahabia", 200, 80,  "right");

      doc.setFont("helvetica", "bold")
      doc.text("Montant", 10, 90);
      doc.setFont("courier", "normal")
      doc.text(newSuccessData.price, 200, 90,  "right");

      doc.setFont("helvetica", "bold")
      doc.text("Date de transaction", 10, 100);
      doc.setFont("courier", "normal")
      doc.text(newSuccessData.time, 200, 100,  "right");

      doc.setFont("helvetica", "bold")
      doc.text("N° de commande", 10, 110);
      doc.setFont("courier", "normal")
      doc.text(newSuccessData.id, 200, 110,  "right");

      doc.setFont("helvetica", "bold")
      doc.text("L'identifiant de la transaction", 10, 120);
      doc.setFont("courier", "normal")
      doc.text(newSuccessData.orderid, 200, 120,  "right");

      doc.setFont("helvetica", "bold")
      doc.text("Le numéro d'autorisation de la transaction", 10, 130);
      doc.setFont("courier", "normal")
      doc.text(newSuccessData.aproval, 200, 130,  "right");

      doc.text('________________________________________________________________',10, 150)

      doc.setFont("helvetica", "bold")
      doc.text("Nom et Prénom", 10, 170);
      doc.setFont("courier", "normal")
      doc.text(newSuccessData.name, 200, 170,  "right");
  
      doc.setFont("helvetica", "bold")
      doc.text("Date de naissance", 10, 180);
      doc.setFont("courier", "normal")
      doc.text(newSuccessData.birthday, 200, 180,  "right");
    
      doc.setFont("helvetica", "bold")
      doc.text("Identifiant Campus France", 10, 190);
      doc.setFont("courier", "normal")
      doc.text(newSuccessData.number, 200, 190,  "right");
    
      doc.setFont("helvetica", "bold")
      doc.text("Numéro de téléphone", 10, 200);
      doc.setFont("courier", "normal")
      doc.text(newSuccessData.phone, 200, 200,  "right");
      
      doc.setFont("helvetica", "bold")
      doc.text("Espace Campus France de rattachement", 10, 210);
      doc.setFont("courier", "normal")
      doc.text(newSuccessData.wilaya, 200, 210,  "right");
      doc.autoPrint();
      doc.output('dataurlnewwindow');
      },
      documentTitle : "Reçu de paiement Campus France"
    });
    //====== download pdf 
    const handleDownloadPdf = async (type) => {
      // const element = componentRef.current;
      // const canvas = await html2canvas(element);
      // const data = canvas.toDataURL('image/png');
  
      // const pdf = new jsPDF('p', 'mm', [297, 210]);
      // const imgProperties = pdf.getImageProperties(data);
      // const pdfWidth = pdf.internal.pageSize.getWidth();
      // const pdfHeight =
      //   (imgProperties.height * pdfWidth) / imgProperties.width;
  
      // pdf.addImage(data, 'PNG', 0, 0, pdfWidth, 297);
      //pdf.save('reçu de paiement.pdf');

      var doc = new jsPDF();
    //   doc.addImage(campus, 'png', 10, 5, 40, 40)
    doc.addImage(ifa, 'png', 10, 15, 40, 20)

      doc.setFontSize(24)
      doc.setTextColor(1, 77, 153)
      doc.setFont("helvetica", "bold")
      doc.text("Reçu de paiement",10, 60)

      doc.setFontSize(14)
      doc.setTextColor(0, 0, 0)


      doc.setFont("helvetica", "bold")
      doc.text("Mode de paiement", 10, 80);
      doc.setFont("courier", "normal")
      doc.text("CIB/ edahabia", 200, 80,  "right");

      doc.setFont("helvetica", "bold")
      doc.text("Montant", 10, 90);
      doc.setFont("courier", "normal")
      doc.text(newSuccessData.price, 200, 90,  "right");

      doc.setFont("helvetica", "bold")
      doc.text("Date de transaction", 10, 100);
      doc.setFont("courier", "normal")
      doc.text(newSuccessData.time, 200, 100,  "right");

      doc.setFont("helvetica", "bold")
      doc.text("N° de commande", 10, 110);
      doc.setFont("courier", "normal")
      doc.text(newSuccessData.id, 200, 110,  "right");

      doc.setFont("helvetica", "bold")
      doc.text("L'identifiant de la transaction", 10, 120);
      doc.setFont("courier", "normal")
      doc.text(newSuccessData.orderid, 200, 120,  "right");

      doc.setFont("helvetica", "bold")
      doc.text("Le numéro d'autorisation de la transaction", 10, 130);
      doc.setFont("courier", "normal")
      doc.text(newSuccessData.aproval, 200, 130,  "right");

      doc.text('________________________________________________________________',10, 150)

      doc.setFont("helvetica", "bold")
      doc.text("Nom et Prénom", 10, 170);
      doc.setFont("courier", "normal")
      doc.text(newSuccessData.name, 200, 170,  "right");
  
      doc.setFont("helvetica", "bold")
      doc.text("Date de naissance", 10, 180);
      doc.setFont("courier", "normal")
      doc.text(newSuccessData.birthday, 200, 180,  "right");
    
      doc.setFont("helvetica", "bold")
      doc.text("Identifiant Campus France", 10, 190);
      doc.setFont("courier", "normal")
      doc.text(newSuccessData.number, 200, 190,  "right");
    
      doc.setFont("helvetica", "bold")
      doc.text("Numéro de téléphone", 10, 200);
      doc.setFont("courier", "normal")
      doc.text(newSuccessData.phone, 200, 200,  "right");
      
      doc.setFont("helvetica", "bold")
      doc.text("Espace Campus France de rattachement", 10, 210);
      doc.setFont("courier", "normal")
      doc.text(newSuccessData.wilaya, 200, 210,  "right");
      type==="print" && doc.autoPrint({variant: 'non-conform'});
      doc.save("Reçu de paiement.pdf")

    };


    const handleEmail = ()=>{
        setIsOpen(true)
    }
     const handleClose =(p)=>{
      setIsOpen(false)
    }
    

    // scroll to the top
    React.useEffect(()=> {
        window.scrollTo(0, 0);
    },[])

    
    
    // prevent rendering twice
    const initialRender = useRef(true); // to prevent useEffect from running twice

// ======================================================================================================
// ======================================================================================================


    // update successData state using data from location + edit time value
    useEffect(()=>{

      // =================== ORIGINAL ========
        /*
        const dataTime = location.state.time;
        const [dateValues, timeValues] = dataTime.split(' ');

        const [day, month, year] = dateValues.split('/');
        const [hours, minutes] = timeValues.split(':');

        const date = new Date(+year, +month - 1, +day, +hours-1, +minutes);

        const newDate = `${date.getHours()+1===24?day-1 : day}/${month}/${year}`
        const newHour = `${date.getHours()}:${minutes}`
 
        const newDateFormat = `${newDate} ${newHour}`   
        // receive data from the precessing page + add newTime to it
        setsuccesData({...location.state,newTime:newDateFormat})
        */
       //================================================== REAL NEW API TEST
        if(Object.keys(newSuccessData).length===0){

            // update newSuccessData state from location
            setNewSuccessData({...location.state})
            // api request
            fetchData()
        
            //------- TESt dummy data-----------
        // update state from location
        /*
        setNewSuccessData({
          price:"12000",
          time:"16/10/2022",
          id:"11525kkl8d9",
          orderid:"22222",
          aproval:"00125",
          desc:"Votre paiement a été effectué avec succés",
        })*/

        // call api
        //testFetchData()
    
        //console.log("======= CALL API ===========")
        }else{
            setLoading(false)
        }

    // ==================================================================
        /* ====== Dummy data =====*/
        //1- update successData from location
        // #option 01 : using the local state : successData
        // setsuccesData({
        //   price:"12000",
        //   newTime:"16/10/2022",
        //   id:"11525kkl8d9",
        //   orderid:"22222",
        //   aproval:"00125",
        //   // name:"aissam sami",
        //   // birthday:"15/03/1990",
        //   // number:"DZ23-44175",
        //   // phone:"0775851123",
        //   // wilaya:"Campus France annaba",
        //   desc:"Votre paiement a été effectué avec succés",
        //   // email:"issamabdou001@gmail.com",
        //   // serverName:serverData.name
        // })
        // #option 02 : using state from payment context : newSuccessData
        
        // setNewSuccessData({
        //   price:"12000",
        //   newTime:"16/10/2022",
        //   id:"11525kkl8d9",
        //   orderid:"22222",
        //   aproval:"00125",
        //   desc:"Votre paiement a été effectué avec succés",
        // })
        //2- =========== dummy api call from server
        /*if (newSuccessData.name == "" || null) {
          fetchData()
        }*/
        //fetchData()
    },[])

    // function : fetchData
    const fetchData = ()=>{
      // call the new api using axios
      const id = location.state.apiId ? location.state.apiId : searchParams.get("orderId")
      var config = {
        method: 'post',
        url: `https://campus.if-algerie.com/api/cibweb/info?orderid=${id}`,
        headers: { }
      };
      axios(config)
      .then(function (response) {
  
        if (response.data.action==="Complete") {
          
            if (response.data.name) {
                // update nesSuccessData state:
                setNewSuccessData((prev)=>(
                    {...prev,
                        name:response.data.name,
                        birthday:response.data.birthday,
                        number:response.data.number,
                        phone:response.data.phone,
                        wilaya:response.data.wilaya,
                        email:response.data.email,
                        price:response.data.price
                        }))
                    // stop loader 
                    setLoading(false)
                    //3- send invoice via email
                    setEmailInvoice(true)
            }else{
                 // stop loading
                setLoading(false)
                // show retry
                setRetry(true)
            }
          
  
        } else if(response.data.action ==="CheckoutError"){
            // stop loading
            setLoading(false)
            // show retry
            setRetry(true)

        }
      
      })
      .catch(function (error) {
        console.log(error);
        // stop loading
        setLoading(false)
        // show retry
        setRetry(true)
      });

    }

    // function : retryFetchingData
    const retryFetchingData = ()=>{
      // hide retry button
      setRetry(false)
      // show loading 
      setLoading(true)
      // fetch data
      fetchData()
      //fetchData2()
      //testFetchData()
    }

    // refetch data succefully
    const fetchData2 = ()=>{
      setTimeout(() => {
        //A/ ====== if : response 
        //1- update successData
      //  setsuccesData((prev)=>(
      //     {...prev,
      //       name:"abderrahmane Aissam 44",
      //       birthday:"15/03/1990",
      //       number:"DZ23-44175",
      //       phone:"0775851123",
      //       wilaya:"Campus France annaba",
      //       email:"issamabdou001@gmail.com"
      //     }))
          setNewSuccessData((prev)=>(
            {...prev,
              name:"slimani amir ",
              birthday:"15/03/1990",
              number:"DZ23-44175",
              phone:"0775851123",
              wilaya:"Campus France annaba",
              email:"amirs@gmail.com",
              price:"6000"
            }))
        //2- stop loading
        setLoading(false)
        //3- send invoice via email
        setEmailInvoice(true)
          })}
    


    
    const infoItemsData = [
      {
        type:"satim",
        data:"CIB/ edahabia",
        title:"Mode de paiment"
      },
      {
        type:"server",
        data:newSuccessData.price,
        title:"Montant"
      },
      {
        type:"satim",
        data:newSuccessData.time,
        title:"Date de transaction"
      },
      {
        type:"satim",
        data:newSuccessData.id,
        title:"N° de commande"
      },
      {
        type:"satim",
        data:newSuccessData.orderid,
        title:"L'identifiant de la transaction"
      }, {
        type:"satim",
        data:newSuccessData.aproval,
        title:"Le numéro d'autorisation de la transaction"
      },

      {
        type:"server",
        data:newSuccessData.name,
        title:"Nom et prénom"
      },
      {
        type:"server",
        data:newSuccessData.birthday,
        title:"Date de naissance"
      },
      {
        type:"server",
        data:newSuccessData.number,
        title:"Identifiant Campus France"
      },
      {
        type:"server",
        data:newSuccessData.phone,
        title:"Numéro de téléphone"
      },
      {
        type:"server",
        data:newSuccessData.wilaya,
        title:"Espace Campus France de rattachement"
      },
    ]

// send email with invoice (only if all the information are in the successData state)
    useEffect(()=>{
  
        if (initialRender.current) {
          // to prevent useEffect from rnunnig two times
          initialRender.current = false;
          //console.log("hhhhhhhhhhhhh")
        } else {
          
            //console.log("======== Email invoice is sent =**========")
            //console.log(newSuccessData.email)
            // console.log(newSuccessData)
            handleEmailInvoice(newSuccessData,newSuccessData.email)
            // handleEmailInvoice(succesData,succesData.email)

      }

    },[emailInvoice])
 
    // old sending email function
    /*
          useEffect(()=>{

      if (initialRender.current) {
        initialRender.current = false;
      } else {
        handleEmailInvoice(succesData,succesData.email)

      }

    },[succesData])
    */



  return (
    <>
      <div className="container py-3 d-flex mb-5 flex-lg-row align-items-center justify-content-between">
        <img src={campus} alt="Campus France" width="150px" />
      </div>
      <div className="">
        <p className="text-center mt-5 py-2  fs-5 ">
          Votre facture vous a été envoyée par mail. Merci de vérifier vos
          spams.
        </p>
      </div>
      <Container>
        <Row>
          <Col lg={8}>
            <div className=" shadow ">
              <div className="p-4   mx-auto mt-5 mb-4" ref={componentRef}>
                {/* <td className={styles.title}>
                        <img src={ifa} alt="l'institur français d'Algérie" className='w-50'/>
                    </td> */}
                {/* <h2 className='p-2 text-center  blue-ifa fw-bold my-4'>{succesData.desc}</h2> */}
                <h2 className="p-2 text-center  blue-ifa fw-bold my-4">
                  {newSuccessData.desc}
                </h2>
                <div>
                  <div className="mb-4">
                    <BsCheckCircle
                      className="mx-auto w-100 fw-light blue-ifa"
                      style={{ fontSize: "70px" }}
                    />
                  </div>
                  <div className="p-3">
                    <ul>
                      {infoItemsData.map((item) => (
                        <InfoItem
                          key={item.title}
                          type={item.type}
                          title={item.title}
                          data={item.data}
                          loading={loading}
                          retry={retry}
                          reFetch={retryFetchingData}
                        />
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {!loading && !retry ?
            <div className=" mx-auto flex-column gap-4 gap-md-0 flex-md-row d-flex align-items-center justify-content-between py-3 mb-3 ">
              <Button className="d-flex align-items-center gap-2 rounded-0 ">
                <HiOutlineDocumentText />
                {retry || loading ? (
                  <span className="text-white">Afficher la facture </span>
                ) : (
                  <Link to="facture" className="text-white" state={succesData}>
                    Afficher la facture{" "}
                  </Link>
                )}
              </Button>
              <Button
                className="d-flex align-items-center gap-2 rounded-0 "
                onClick={handleEmail}
              >
                <RiMailSendLine />
                Envoyer par email
              </Button>
              <Button
                className="d-flex align-items-center gap-2 rounded-0"
                onClick={() => handlePrint()}
              >
                <FaPrint />
                Imprimer
              </Button>
              <Button
                className="d-flex align-items-center gap-2 rounded-0"
                onClick={() => handleDownloadPdf()}
              >
                <HiDownload />
                Télécharger
              </Button>
            </div>
            :
            null}
            {/* <div className="d-none">
                    <RecuDePaiment  ref={recuRef}/>
                  </div> */}
          </Col>
          <Col lg={4}>
            <HelpCallCard />
            <SatimCard />
          </Col>
        </Row>
        <ModalCmp open={isOpen} onClose={handleClose} values={succesData}>
          <EmailPopUp type="recu" onClose={handleClose} values={succesData} />
        </ModalCmp>
      </Container>
    </>
  );
}

export default SuccessPage