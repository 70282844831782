import React from 'react'
import { FiPhoneCall } from "react-icons/fi";

function HelpCallCard() {
  return (
    <div className=' p-4 text-center mt-5' style={{backgroundColor:"whitesmoke"}}>
      <div ><FiPhoneCall className='text-center fw-light' style={{fontSize:"3.5rem"}}/></div>
      <h5 className='text-center mt-2'> Besoin<span className='text-danger'> d'aide ?</span></h5>
      <h4 className='blue-ifa mt-3 '>+213 562 45 77 02</h4>
      <small className='text-center fw-light'>Dimanche - Jeudi / 8.00 - 17.00</small>
    </div>
    // <div className='p-3 px-5 text-center' style={{backgroundColor:"whitesmoke",height:"12.8rem"}}>
    //   <div ><FiPhoneCall className='text-center fw-light' style={{fontSize:"2.8rem"}}/></div>
    //   <div>
    //     <h5 className='text-center mt-2'> Besoin<span className='text-danger'> d'aide ?</span></h5>
    //     <h5 className='blue-ifa mt-3 fw-bold' style={{fontSize:"1.5rem"}}>+213 562 45 77 02</h5>
    //     <small className='text-center fw-light'>Dimanche - Jeudi / 8.00 - 17.00</small>
    //   </div>
    // </div>
  )
}

export default HelpCallCard