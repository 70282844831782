export const handleEmailInvoice = (values,mail)=>{
    const config={
      SecureToken: 'b68c6b3e-451b-4147-b2a9-a7eb1d9be99d',
      To: mail,
      From: "institutfrancais.paiement@gmail.com",
      Subject: "Facture de paiement -l'institut Français d'Algérie-",
      Body: `
      <head> \
      <style>
  .invoice-box {
    max-width: 800px;
    margin: auto;
    padding: 30px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    font-size: 16px;
    line-height: 24px;
    font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    color: #555;
  }

  .invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
  }

  .invoice-box table td {
    padding: 5px;
    vertical-align: top;
  }

  .invoice-box table tr td:nth-child(2) {
    text-align: right;
  }

  .invoice-box table tr.top table td {
    padding-bottom: 20px;
  }

  .invoice-box table tr.top table td.title {
    font-size: 45px;
    line-height: 45px;
    color: #333;
  }

  .invoice-box table tr.information table td {
    padding-bottom: 40px;
  }

  .invoice-box table tr.heading td {
    background: #eee;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
  }

  .invoice-box table tr.details td {
    padding-bottom: 20px;
  }

  .invoice-box table tr.item td {
    border-bottom: 1px solid #eee;
  }

  .invoice-box table tr.item.last td {
    border-bottom: none;
  }

  .invoice-box table tr.total td:nth-child(2) {
    border-top: 2px solid #eee;
    font-weight: bold;
  }

  

  /** RTL **/
  .invoice-box.rtl {
    direction: rtl;
    font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  }

  .invoice-box.rtl table {
    text-align: right;
  }

  .invoice-box.rtl table tr td:nth-child(2) {
    text-align: left;
  }
  .logo{
    width:"30px";
  }
</style>
  </head>
  <body>
  <p>Votre paiement à été effectué avec succès, veuillez trouver ci-dessous votre facture</p>
  <div class="invoice-box">
      <table cellpadding="0" cellspacing="0">
          <tr class="top">
              <td colspan="2">
                  <table>
                      <tr>
                      <td class='title'>
                            <!--<img src="https://www.if-algerie.com/site-Plone.png" alt="l'institut français d'Algérie" class='logo'/>-->
                            l'institut français d'Algérie
                        </td>

                          <td>
                              Facture N° #: <span>${values.id}</span><br />
                              ${values.time}
                          </td>
                      </tr>
                  </table>
              </td>
          </tr>

          <tr class="information">
              <td colspan="2">
                  <table>
                      <tr>
                          

                      <td>
                      ${values.name} <br />
                      ${values.wilaya}
                        </td>
                      </tr>
                  </table>
              </td>
          </tr>

          <tr class='heading'>
                <td>Méthode de paiment</td
                <td>CIB/ edahabia</td>
            </tr>

            <tr class='details'>
                <td>Tarif</td>

                <td>${values.price} DZD</td>
            </tr>

      </table>
  </div>
</body>`,
      
      
    }
    if(window.Email) {
      
      window.Email.send(config)
      
     
 }

  }