import React from 'react'

import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import SocialIcon from './SocialIcon';

const ICON_STYLE= {
    color:"rgb(0, 146, 213)",
    width:"50px",
    height:"50px",
    border:"solid 1px rgb(0, 146, 213)",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    borderRadius:"50%"
}

const socials=[
    {
        type:"Facebook",
        link:"https://www.facebook.com/IFAlgerie/"
    },
    {
        type:"Instagram",
        link:"https://instagram.com/ifalgerie?igshid=YmMyMTA2M2Y= "
    },
    {
        type:"Youtube",
        link:"https://www.youtube.com/channel/UCwFxKRpsl-N5cSJKEYbDyyQ "
    },
    {
        type:"Tiktok",
        link:"https://vm.tiktok.com/ZMNcrjx7v/ "
    },
    {
        type:"LinkedIn",
        link:"#"
    }

]

const socialIconsList = socials.map((el,index)=>{
    return <SocialIcon key={index} type={el.type} link={el.link}/>
})
function SocialMediaIcons({className}) {
  return (
    <div className={`${className} d-flex justify-content-center gap-3 gap-sm-5 fs-2`}>
        {socialIconsList}
    </div>
  )
}

export default SocialMediaIcons