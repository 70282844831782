
import React,{useEffect, useState} from 'react'
import CoverImg from 'components/General/CoverImg'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import { Container,Button, Row, Col } from 'react-bootstrap'
import subscription from "../../img/subscription.jpg"
import { FaRegTimesCircle } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import satim from "../../img/satim-number.png"
import { useLocation,useSearchParams } from 'react-router-dom'
import {usePayment} from "../../contexts/payment-Context/PaymentContext"
import axios from 'axios'
import HelpCallCard from './components/HelpCallCard'
import SatimCard from './components/SatimCard'
import campus from "../../img/campus-png.png"
import satimLogo from "../../img/satim-logo.png"
import BgShape from 'components/General/BgShape'



function FailedPaymentPage() {
    const [errorData,setErrorData]= useState({})
    // const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation()
    // const {errValues,setErrorValues,formValues,setFormValues,formErrors,setFormErrors,isOpen,setIsOpen,payment,setPayment,tarif,setTarif,isChecked,setIsChecked,isRecaptcha,setIsRecaptcha} = usePayment()

    const greenNumber = (
        <g xmlns="http://www.w3.org/2000/svg">
            <g id="Layer_2">
                <g id="Layer_1-2">
                    <path class="st0" d="M24.6,4.3h134.8c13.3,0,24,10.7,24,24l0,0c0,13.3-10.7,24-24,24H24.6c-13.3,0-24-10.7-24-24l0,0     C0.6,15,11.3,4.3,24.6,4.3z"/>
                    <circle class="st1" cx="25.5" cy="28.3" r="20"/>
                    <path class="st0" d="M18.8,15.7c0,0-6.6,0-5.7,5.3c1.2,7.5,10.4,17.7,17.7,19.6c5.2,1.4,5.9-5.1,5.9-5.1c-1.4-1.7-3.4-2.8-5.6-3     c-1.8-0.3-1.4,1.5-2.8,1.6c-0.4,0-3.2-1.8-5.4-4.2s-3.8-5.4-3.7-5.8c0.3-1.4,2-0.8,1.9-2.6C21.3,19.4,20.4,17.3,18.8,15.7     L18.8,15.7z"/>
                    <path class="st0" d="M24.5,22.4c-0.5,0.1-1.1-0.2-1.2-0.8c-0.1-0.5,0.2-1.1,0.8-1.2c0.2,0,0.3,0,0.5,0c1.2,0.1,2.3,0.4,3.4,0.9     c2.2,1.1,3.9,3,4.8,5.3c0.4,1.1,0.6,2.2,0.6,3.4c0.1,0.5-0.2,1.1-0.7,1.2s-1.1-0.2-1.2-0.7c0-0.2,0-0.3,0-0.5     c0-0.9-0.1-1.8-0.5-2.6c-0.4-0.9-0.9-1.7-1.5-2.5c-0.6-0.7-1.4-1.3-2.3-1.8C26.3,22.7,25.4,22.5,24.5,22.4L24.5,22.4z"/>
                    <path class="st0" d="M24.4,17.7c-0.5,0-0.9-0.5-0.9-1s0.5-0.9,1-0.9c1.8,0.1,3.5,0.6,5.1,1.4c3.4,1.7,6,4.6,7.4,8.2     c0.7,1.7,1,3.5,0.9,5.3c-0.1,0.5-0.7,0.8-1.2,0.7C36.3,31.3,36,31,36,30.6c0.1-1.5-0.2-3.1-0.8-4.5C34,23,31.7,20.5,28.8,19     C27.4,18.3,25.9,17.9,24.4,17.7L24.4,17.7z"/>
                    <g class="st2">
                        <path class="st1" d="M67.3,36.1c0,0,3.2,3.5,7.7,3.5c3.6,0,6.1-2.2,6.1-5.1c0-3.6-3.3-5.3-7.3-5.3h-2.2L70.2,26l6.2-7.4      c1.3-1.6,2.4-2.6,2.4-2.6v-0.1c0,0-1,0.1-3,0.1H65.7v-5.3h20.8v3.9l-8.4,9.6c4.7,0.7,9.3,4,9.3,9.9c0,5.8-4.4,11.2-12,11.2      c-7.2,0-11.3-4.6-11.3-4.6L67.3,36.1z"/>
                        <path class="st1" d="M91.4,27.7c0-9.8,3-17.6,12.3-17.6s12.4,7.8,12.4,17.6s-3.1,17.7-12.4,17.7S91.4,37.5,91.4,27.7z       M109.7,27.7c0-6.6-1.3-11.9-6-11.9s-6,5.2-6,11.9s1.3,12,6,12S109.7,34.4,109.7,27.7z"/>
                        <path class="st1" d="M119.9,41.8c0-12.3,15.9-14.3,15.9-21c0-3-2.3-4.8-5.2-4.8c-4,0-6.1,4-6.1,4l-4.6-3.1c0,0,3.1-6.8,11.3-6.8      c6,0,11.1,3.6,11.1,10.1c0,10.9-15.3,12.8-15.4,19.2h15.9v5.3h-22.6C120,43.7,119.9,42.7,119.9,41.8z"/>
                        <path class="st1" d="M146.6,27.7c0-9.8,3-17.6,12.3-17.6c9.3,0,12.4,7.8,12.4,17.6s-3.1,17.7-12.4,17.7      C149.7,45.4,146.6,37.5,146.6,27.7z M165,27.7c0-6.6-1.3-11.9-6-11.9c-4.7,0-6,5.2-6,11.9s1.3,12,6,12      C163.7,39.7,165,34.4,165,27.7z"/>
                    </g>
                </g>
            </g>
            <g>
                <path class="st0" d="M10.7,70.7l-1.1,2.8H5.7l6.5-15h3.9l6.3,15h-4.1l-1.1-2.8H10.7z M14,62.3l-2.2,5.5h4.3L14,62.3z"/>
                <path class="st0" d="M30.3,58.5c3.8,0,6,1.9,6,5.2c0,3.5-2.2,5.5-6,5.5h-2.7v4.2h-3.8v-15H30.3z M27.6,66.3h2.5    c1.7,0,2.6-0.8,2.6-2.4c0-1.6-1-2.4-2.6-2.4h-2.5V66.3z"/>
                <path class="st0" d="M44.9,58.5c3.8,0,6,1.9,6,5.2c0,3.5-2.2,5.5-6,5.5h-2.7v4.2h-3.8v-15H44.9z M42.2,66.3h2.5    c1.7,0,2.6-0.8,2.6-2.4c0-1.6-1-2.4-2.6-2.4h-2.5V66.3z"/>
                <path class="st0" d="M56.7,61.5v3h7.2v3h-7.2v3h8.2v3h-12v-15h11.7v3H56.7z"/>
                <path class="st0" d="M77.5,70.3v3.2H67.3v-15h3.8v11.8H77.5z"/>
                <path class="st0" d="M97.6,65.9v5.8c-1.6,1.1-4.1,1.9-6.2,1.9c-4.6,0-8-3.3-8-7.7c0-4.3,3.5-7.6,8.3-7.6c2.2,0,4.6,0.9,6.1,2.2    l-2.1,2.6c-1.1-1-2.6-1.6-4-1.6c-2.5,0-4.3,1.9-4.3,4.4c0,2.5,1.9,4.5,4.4,4.5c0.8,0,1.8-0.3,2.7-0.7v-3.9H97.6z"/>
                <path class="st0" d="M107,69.3h-0.1H104v4.2h-3.8v-15h6.7c4,0,6.2,1.9,6.2,5.2c0,2.3-1,3.9-2.7,4.8l3.1,4.9h-4.3L107,69.3z     M106.9,66.3c1.6,0,2.6-0.8,2.6-2.4c0-1.6-1-2.4-2.6-2.4H104v4.8H106.9z"/>
                <path class="st0" d="M119.1,70.7l-1.1,2.8h-3.9l6.5-15h3.9l6.3,15h-4.1l-1.1-2.8H119.1z M122.4,62.3l-2.2,5.5h4.3L122.4,62.3z"/>
                <path class="st0" d="M137.5,61.6v11.9h-3.8V61.6h-4.5v-3.1H142v3.1H137.5z"/>
                <path class="st0" d="M157.2,67.2c0,4-2.6,6.5-6.8,6.5c-4.2,0-6.9-2.5-6.9-6.5v-8.7h3.8v8.7c0,2,1.3,3.3,3.1,3.3c1.8,0,3-1.2,3-3.3    v-8.7h3.8V67.2z"/>
                <path class="st0" d="M159.9,73.5v-15h3.8v15H159.9z"/>
                <path class="st0" d="M173.6,61.6v11.9h-3.8V61.6h-4.5v-3.1h12.8v3.1H173.6z"/>
            </g>
        </g>
    )

    // useEffect(()=>{
    //     const id = searchParams.get("orderId")
    //       var config = {
    //         method: 'post',
    //         url: `https://campus.if-algerie.com/api/cibweb/Success?orderid=${id}&total=100`,
    //         headers: { }
    //       };
    //       axios(config)
    //       .then(function (response) {
    //         console.log("all respone ")
    //         console.log(response)
    //         console.log("proccessing data response")
    //         console.log(JSON.stringify(response.data));
    //         console.log(response.data);
    //         setErrorData(response.data)
            
          
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //       });
    //   },[])
    // const {action, errorCode , desc , desc2 , orderId , transactionId } = errorData

    useEffect(()=>{
        setErrorData(location.state)
    },[])
    
  return (
    <div className='position-relative overflow-hidden'>
    <BgShape className="d-none d-sm-block" type="red"  right="-80px" top="-50px" rotate="-140deg"/>
    <BgShape className="d-none d-sm-block" type="blue" left="-80px" top="370px" rotate="140deg"/>
  
        <Container className="py-3">
        <div className='py-3 d-flex flex-lg-row align-items-center justify-content-between'>
            <img src={campus} alt="Campus France" width='160px'/>
            <img src={satimLogo} alt="satim" width='200px'/>
        </div>
            <Row className='mt-5 mt-md-2'>
                <Col md={8} className="d-flex justify-content-center align-items-center">
                <div>
                    <div className=' text-center' style={{color:"#E3041B"}}>
                        <FaRegTimesCircle className=' mb-4' style={{fontSize:"4rem"}}/>
                        <h6 className='fw-bold text-danger text center fs-5' >{errorData.desc}</h6>
                        {/* <h6 className='fw-bold text center fs-5' >Votre transaction a été rejetée, veuillez contacter votre banque . Code erreur : 2016</h6> */}
                    </div>
                    <div className='text-start'>
                        <span className='d-block mb-2 text-center mt-4'>Commande N°: {errorData.orderId}</span>
                        {/* <span className='d-block mb-2 text-center mt-4'>Commande N°: 145226259</span> */}
                    </div>

                </div>
                    {/* <div className='relative mt-5 d-md-flex flex-column flex-md-row gap-5 flex-wrap align-items-end justify-content-between'>
                        <HelpCallCard/>
                        <SatimCard/>
                    </div> */}
                </Col>
                <Col md={4} className="mt-5 mt-md-0">
                    <HelpCallCard/>
                    <SatimCard/>
                </Col>
            </Row>
            <div>

            </div>
            
        </Container>
    </div>
  )
}

export default FailedPaymentPage