import React from 'react'
import InlineDotsLoader from './InlineDotsLoader';
import styles from "../campus.module.css"
import { BsArrowRepeat } from "react-icons/bs";


const InfoItem = ({data,type,title,loading,retry,reFetch}) => {
    const displayData = ()=>{
        if(loading && !retry){
            return <InlineDotsLoader />
        } else if (retry && !loading){
            return <button className={styles.retry} onClick={()=>reFetch()}><BsArrowRepeat/> Réessayer</button>
        } else if(!retry && !loading) {
            return <div>{data} </div>
        }
    }
  return (
    type =="satim"?
    <li className="py-3 d-flex align-items-sm-center justify-content-between flex-column flex-sm-row align-items-start">
      <div className="fw-bold text-start">{title}</div>
      <div>{data}</div>
    </li>
    :
    <li className="py-3 d-flex align-items-sm-center justify-content-between flex-column flex-sm-row align-items-start">
      <span className="fw-bold text-start">{title}</span>
      {/* {!loading ? (
        <div>{data} </div>
      ) : (
        <InlineDotsLoader />
      )} */}
      {displayData()}
    </li>
  );
}

export default InfoItem