import React from 'react'
import {Col,Card,Button,Badge} from "react-bootstrap";
import { Link } from 'react-router-dom';
function EventCard({event}) {
    // Change the color of the badge according to the region
    const backgrounds = (region)=>{
        switch (region) {
            case "alger":
                return "danger"
                break;
            case "constantine":
                return "warning"
                break;
            case "oran":
                return "success"
                break;
            case "annaba":
                return "black"
                break;
            case "telemcen":
                return "secondary"
                break;
    
        
            default:
                break;
        }
    }

  return (
          <Card className="card mb-3">
            <span className="zoom-effect-container">
              <h4 style={{ top: "10px", position: "absolute" }}>
                <Badge
                  style={{
                    position: "absolute",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    textTransform:"uppercase"
                  }}
                  bg={backgrounds(event.region)}
                >
                  {event.region}
                </Badge>
              </h4>
              <h5 style={{ bottom: "15px", position: "absolute" }}>
                <Badge  
                    style={{
                    position: "absolute",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    color:"black"
                  }}
                  bg="white">
                    {event.date}
                </Badge>
              </h5>
              <img src={event.image} className="card-img-top" />
            </span>
            <Card.Body>
              <Card.Title
                style={{ textAlign: "initial", color: "rgb(20, 20, 20)" }}
              >
                {event.title}
              </Card.Title>
              <Card.Text
                style={{
                  textAlign: "initial",
                  marginLeft: "-3px",
                  color: "rgb(119, 117, 119)",
                }}
              >
                 {event.description}
              </Card.Text>
            </Card.Body>
            <Card.Body
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Link to={`${event.title}`}>
                <Button
                  variant="link"
                  style={{
                    color: "black",
                    textDecorationColor: "#ffed00",
                    textDecorationThickness: "3px",
                  }}
                >
                  Lire la suite
                </Button>
              </Link>
              <Button variant="outline-danger" style={{ color: "black",transform:"capitalize" }}>
                {event.type}
              </Button>
            </Card.Body>
          </Card>
  )
}

export default EventCard