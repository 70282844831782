import React ,{useState,useRef} from 'react'
import { Button } from 'react-bootstrap';
import { FaCheckCircle } from "react-icons/fa";
import styles from "../campus.module.css"


function PriceCard({title,price,handlePriceClick,montant}) {
  const [isClickedPlein,setIsClickedPlein] = useState(false)
  const [isClickedReduit,setIsClickedReduit] = useState(false)


  const selectPrice = (e)=>{
    e.preventDefault()
    handlePriceClick(price)
    
  }
  const CARD_STYLE_REDUIT={
    cursor:"pointer",
    outline: montant===6000 && "rgb(0, 146, 213) 2px solid",
    backgroundColor: montant===6000 && "rgb(0, 146, 213)"

  }
  const CARD_STYLE_PLEIN={
    cursor:"pointer",
    outline: montant===12000 && "rgb(0, 146, 213) 2px solid",
    backgroundColor: montant===12000 && "rgb(0, 146, 213)"
  }
  const TEXT_STYLE_PLEIN={
    color:montant===12000 && "white"
  }
  const TEXT_STYLE_REDUIT={
    color:montant===6000 &&"white"
  }
  return (
    <button
      className="mb-4 border border-white h-100 "
      onClick={selectPrice}
      style={price === 12000 ? CARD_STYLE_PLEIN : CARD_STYLE_REDUIT}
    >
      <div className="card-header py-3 px-4 text-center rounded-0 border-0">
        <h4
          className="my-0 fw-bold blue-ifa"
          style={price === 12000 ? TEXT_STYLE_PLEIN : TEXT_STYLE_REDUIT}
        >
          {" "}
          {price} {price!==''?'DA':''} 
        </h4>
        <span
          className="mt-2 d-block"
          style={price === 12000 ? TEXT_STYLE_PLEIN : TEXT_STYLE_REDUIT}
        >
          <input
            type="radio"
            checked={price === 12000 ? montant === 12000 : montant === 6000}
            className={styles.tarif}
          />{" "}
          {/*Number(price) === 12000 ? "Plein**" : "Réduit**"*/ title}
        </span>
      </div>
    </button>
  );
}

export default PriceCard