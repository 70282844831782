import React from 'react'
import {Form,Button} from 'react-bootstrap';
import { FaTimes } from "react-icons/fa";

function NewsLetterForm({open,onClose}) {
    const bgStyle={
        postion:'fixed',
        top:"0",
        left:"0",
        right:"0",
        bottom:"0",
        // width:"100%",
        // height:"100%",
        zIndex:"44",
        backgroundColor:"grey"
    }
// if (!open) {
//     return null 
// }



  return(
    
      <Form className=' mx-auto text-start px-3 py-4 bg-white rounded relative'>
        <Button className='bg-transparent text-secondary border-0 ' style={{position:"absolute",right:"2px",top:"2px"}} onClick={onClose}><FaTimes /></Button>
        <h4 className='text-start my-3 fw-bold' style={{color: 'rgb(0, 146, 213)'}}>Newsletter</h4>
      <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Nom</Form.Label>
          <Form.Control type="text" placeholder="Entrez votre nom" />
        </Form.Group>

        <Form.Group className="mb-2" controlId="formBasicEmail">
          <Form.Label>Adresse email</Form.Label>
          <Form.Control type="email" placeholder="Entrez votre adresse email" />
        </Form.Group>
        <Button style={{backgroundColor:"rgb(0, 146, 213)"}} type="submit" className="ms-auto mt-5 d-flex align-items-end " onClick={onClose}>
          Confirmez l'inscription 
        </Button>
      </Form>
   
  );
}

export default NewsLetterForm