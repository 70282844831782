// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBwi4qjR5tuudPzbubAA9im4yJ6V6Uu3nA",
  authDomain: "ifa-paiement.firebaseapp.com",
  projectId: "ifa-paiement",
  storageBucket: "ifa-paiement.appspot.com",
  messagingSenderId: "121730147826",
  appId: "1:121730147826:web:5f5c2ad3b34cbbeec7b76b",
  measurementId: "G-GYSEPK5TK4"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);
const perf = getPerformance(firebase);
export default firebase;