import React, {useContext,useEffect,useState} from 'react'
import { Container } from 'react-bootstrap'
import CampusSubscriptionForm from './CampusSubscriptionForm'
import BgShape from 'components/General/BgShape'
import {usePayment} from "../../contexts/payment-Context/PaymentContext"
import campus from "../../img/campus-png.png"
import Modal from './components/PopUpModal'
import PaymentMessagePopUp from './components/PaymentMessagePopUp'
// export const paymentContext = createContext('')

function CampusInscriptionPage() {
  const [isModalOpen,setIsModalOpen] = useState(false)
  const {formValues,setFormValues,formErrors,setFormErrors,isOpen,setIsOpen,payment,setPayment,tarif,setTarif,isChecked,setIsChecked,isRecaptcha,setIsRecaptcha} = usePayment()

  // scroll to the top
  useEffect(()=> {
    window.scrollTo(0, 0);
},[])

   // handle colsing message popup
   const handleClose =()=>{
    setIsModalOpen(false)
  }

  useEffect(()=>{
    setIsModalOpen(true)
  },[])


// const initialValues = { nom: "", prenom:"",numDz:"",telephone:"",naissance:"",email: "",wilaya:"",montant:""};
//     const [formValues, setFormValues] = useState(initialValues);
//     const [formErrors, setFormErrors] = useState({});
//     const [isOpen,setIsOpen] = useState(false)
//     const [payment,setPayment] = useState(false)
//     const [tarif,setTarif] = useState(0)
//     // const [startDate, setStartDate] = useState(new Date());
//     // condition d'utilisation
//     const [isChecked,setIsChecked] = useState(false)
//     // recapthcha state
//     const [isRecaptcha,setIsRecaptcha] = useState(false)
//     const paymentContextValue = {formValues,setFormValues,formErrors,setFormErrors,isOpen,setIsOpen,payment,setPayment,tarif,setTarif,isChecked,setIsChecked,isRecaptcha,setIsRecaptcha}

  return (
    <div style={{backgroundColor:"whitesmoke",zIndex:"1"}} className=" position-relative overflow-hidden">
        <BgShape type="yellow"  right="-80px" top="-50px" rotate="-140deg"/>
        <BgShape type="blue"  right="-50px" top="750px" rotate="-150deg"/>
        <BgShape type="red"  left="-80px" top="500px" rotate="140deg"/>
        {/* <paymentContext.Provider value={paymentContextValue}> */}
            <Container>
              <div className='d-flex flex-column-reverse flex-md-row  justify-content-between align-items-center mt-5 ms-5 me-5 '>
                <h1 className="text-center mt-5" style={{color: 'rgb(0, 146, 213)',fontWeight: 'bold'}}>Paiement</h1>
                <img src={campus} className="" style={{width:"150px"}}/>
              </div>
              <CampusSubscriptionForm/>
              <Modal open={isModalOpen} onClose={handleClose}>
               <PaymentMessagePopUp onClose={handleClose}/>
              </Modal>
            </Container>
        {/* </paymentContext.Provider> */}
            
        
    </div>
  )
}

export default CampusInscriptionPage