import React,{useState} from 'react'
import styles from "./menuItemsList.module.css"
import Dropdown from './Dropdown'
import { Link } from 'react-router-dom'



function MenuItems({items,depthLevel,className,style,hide}) {
  const [dropdown, setDropdown] = useState(false);

  // show the Dropdown menu when hovering on the menu item
  const onMouseEnter = () => {
   setDropdown(true);
   };
   // hide Dropdown menu when the mouse leave the menu item
   const onMouseLeave = () => {
    setDropdown(false);
   };
   // close the Dropdown when clicking on a menu item
   const closeDropdown = () => {
    dropdown && setDropdown(false);
  };

  

  // display all the submenu items of etudes together
  const etudesItems = (menu)=>{
    menu.submenu.map((sub,index)=>{
      return sub.title
    })
  }

 
  return (
    <li className={`nav-item ${styles.menu_items} ${className}`}  onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={closeDropdown}>
      {/* display the submenu items directly for "Etudes" */}
      {
        
        items.submenu ? (
          <>
            <button type="button" aria-haspopup="menu" aria-expanded={dropdown ? "true" : "false"} style={style} >
              {depthLevel ===0 ? items.title.toUpperCase():items.title}{' '}
              {depthLevel > 0 ? <span>&raquo;</span> : <span className={styles.arrow} />}
            </button>

            <Dropdown title={items.title} submenus={items.submenu} dropdown={dropdown} depthLevel={depthLevel}  />
          </>
        ) : (
          <Link to={items.url} >{items.title}</Link>
        )
      }

      {/* {items.submenu ? (
        <>
          <button type="button" aria-haspopup="menu" aria-expanded={dropdown ? "true" : "false"} style={items.title=="etudes"?{backgroundColor:"red"}: style} >
            {depthLevel ==0 ? items.title.toUpperCase():items.title}{' '}
            {depthLevel > 0 ? <span>&raquo;</span> : <span className={styles.arrow} />}
          </button>
          <Dropdown submenus={items.submenu} dropdown={dropdown} depthLevel={depthLevel} display={displayStyle()} />
        </>
      ) : (
        <Link to={items.url} >{items.title}</Link>
      )} */}

      {/* 
         {items.submenu ? (
        <>
          <button type="button" aria-haspopup="menu" aria-expanded={dropdown ? "true" : "false"} style={style} >
            {depthLevel ==0 ? items.title.toUpperCase():items.title}{' '}
            {depthLevel > 0 ? <span>&raquo;</span> : <span className={styles.arrow} />}
          </button>
          <Dropdown submenus={items.submenu} dropdown={dropdown} depthLevel={depthLevel} />
        </>
      ) : (
        <Link to={items.url} >{items.title}</Link>
      )}
      
      */}
    </li>
  )
}

export default MenuItems