import React, { Component, lazy, useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as IconCalendarEvent } from "bootstrap-icons/icons/calendar-event.svg";
import { ReactComponent as IconHandThumbsUp } from "bootstrap-icons/icons/hand-thumbs-up.svg";
import { ReactComponent as IconChatText } from "bootstrap-icons/icons/chat-text.svg";
import {Accordion, Row,Col} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {FaPhoneSquareAlt}from 'react-icons/fa';
import {ImLocation2} from 'react-icons/im';
import {
  faTwitter,
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
// const Widgets = lazy(() => import("../../components/blog/Widgets"));
import img2 from "../../img/alger4.jpg";
import img1 from "../../img/alger3.jpg";
import cultureteque from "../../img/cultureteque.png";
import decalog from "../../img/decalog.png";
import MainHeader from "../header/MainHeader";
import Footer from "../footer/Footer";
import Breadcrumb from "components/breadcrumb/breadcrumb";

class BlogDetailView extends Component {
  constructor(props) {
    super();
    this.state = {};
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <React.Fragment>
        <div
          className="bg-dark text-white rounded-0 bg-image"
          style={{
            height: "14em",
            backgroundImage: `url(${img2})`,
            backgroundSize: "Cover",
            backgroundPosition: "Center",
          }}
        ></div>
        <Breadcrumb></Breadcrumb>
        <section id="main">
          <div className="container mt-3">
            <div className="row">
              <div className="col-md-8">
                <div className="display-6 border-bottom pb-3 mb-1">
                  Mediathéque d'Alger
                </div>
                <p>
                  La Médiathèque de l'Institut français d’Alger a pour mission
                  de répondre au besoin de culture, de loisir et d'information
                  sur la France contemporaine. Elle s'adresse à tous les publics
                  : petits et grands, collégiens, lycéens et étudiants,
                  enseignants, professionnels, retraités...
                </p>
                <hr />
                <Accordion /*defaultActiveKey={['0']}*/ alwaysOpen>
                  <Accordion.Item /*eventKey="0"*/>
                    <Accordion.Header>
                      Descriptif de la médiathèque et de ses différents espaces
                    </Accordion.Header>
                    <Accordion.Body>
                      (Jeunesse, Multimédia, Littérature, Bande dessinée,
                      Technique, Bibliothèque de l’apprenant, Fonds arabe, Livre
                      audio (toutes les médiathèques n’ont pas forcément les
                      mêmes espaces)...
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item /*eventKey="1"*/>
                    <Accordion.Header>Ateliers et rencontres </Accordion.Header>
                    <Accordion.Body>
                      descriptif par atelier avec modalités de fonctionnement.
                      Ex : Clubs (lecteurs, poètes...) ; Choix Goncourt;
                      Ateliers enfants (cela dépend des médiathèques)...
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item /*eventKey="2"*/>
                    <Accordion.Header>Inscriptions </Accordion.Header>
                    <Accordion.Body>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Row style={{ marginBottom: "24px" }}></Row>
              </div>
              <div className="col-md-4">
                <div class="p-4 mb-3 bg-light rounded ">
                  <a
                    href="https://www.culturetheque.com/DZA/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={cultureteque} alt="..."></img>
                  </a>
                </div>
                <div class="p-4 mb-3 bg-light rounded ">
                  <a
                    href="https://www.decalog.net/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ width: "100%" }}
                      src={decalog}
                      alt="..."
                    ></img>
                  </a>
                </div>
                <div class="p-4 mb-3 bg-light rounded ">
                  <h4 class="font-italic">Contact & accès </h4>

                  <p class="mb-0 text-start">
                    <ImLocation2 /> Institut français d'Algerie - 7, rue Hassani
                    Issad - 16000 Alger
                  </p>
                  <p class="mb-0 text-start">
                    <FaPhoneSquareAlt /> 213 (0) 21 73 78 20/21
                  </p>
                </div>
                <div class="p-4 mb-3 bg-light rounded ">
                  <h4 class="font-italic">Horaires d’ouverture </h4>
                  <Row>
                    <Col xs className="text-start">
                      Dimanche
                    </Col>
                    <Col xs className="text-end">
                      10h-19h
                    </Col>
                  </Row>
                  <Row>
                    <Col xs className="text-start">
                      Lundi
                    </Col>
                    <Col xs className="text-end">
                      10h-19h
                    </Col>
                  </Row>
                  <Row>
                    <Col xs className="text-start">
                      Mardi
                    </Col>
                    <Col xs className="text-end">
                      10h-19h
                    </Col>
                  </Row>
                  <Row>
                    <Col xs className="text-start">
                      Mercredi
                    </Col>
                    <Col xs className="text-end">
                      10h-19h
                    </Col>
                  </Row>
                  <Row>
                    <Col xs className="text-start">
                      Jeudi
                    </Col>
                    <Col xs className="text-end">
                      10h-19h
                    </Col>
                  </Row>
                  <Row>
                    <Col xs className="text-start">
                      Vendredi
                    </Col>
                    <Col xs className="text-end">
                      10h-19h
                    </Col>
                  </Row>
                  <Row>
                    <Col xs className="text-start">
                      Samedi
                    </Col>
                    <Col xs className="text-end">
                      10h-19h
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default BlogDetailView;
