
import React, {useState,useEffect, useContext} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form,Row,Col,Button } from 'react-bootstrap'
import Modal from './components/PopUpModal'
import RECAPTCHA from "react-google-recaptcha";
import ConfirmationPopUp from './ConfirmationPopUp'
import PriceCard from './components/PriceCard'
import cib from "../../img/CIB.png"
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { paymentContext } from './CampusInscriptionPage';
import {usePayment} from "../../contexts/payment-Context/PaymentContext"
import list from "../../list.pdf"
import { IoHelpCircleOutline } from "react-icons/io5";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import cvv from "../../img/cvv.jpeg"
import { handleConfirmationEmail } from './helpers/useConfirmationEmail';
import InlineDotsLoader from './components/InlineDotsLoader';
import campus from "../../img/campus-png.png"
import satim from "../../img/satim-logo.png"
import phone from "../../img/satim-number.png"
import { Container } from 'react-bootstrap';

function CampusSubscriptionForm() {
  // const {formValues,setFormValues,formErrors,setFormErrors,isOpen,setIsOpen,payment,setPayment,tarif,setTarif,isChecked,setIsChecked,isRecaptcha,setIsRecaptcha} = useContext(paymentContext)
  const {formValues,setFormValues,formErrors,setFormErrors,isOpen,setIsOpen,payment,setPayment,tarif,setTarif,isChecked,setIsChecked,isRecaptcha,setIsRecaptcha,tObject,setTObject} = usePayment()
  const [loading,setLoading] = useState(false)
  const navigate = useNavigate()
  const tarifs = [
    /*{
    'id':1,
    'title':'Je suis boursier',
    'montant':0

  },{
    'id':2,
    'title':'J’ai un baccalauréat français (élève scolarisé cette année au LIAD)',
    'montant':0

  },*/{
    'id':3,
    'title':'Je suis accepté via Campus Art',
    'montant':10000

  },{
    'id':4,
    'title':'Je postule pour un DFMS/ DFMSA',
    'montant':10000

  },/*{
    'id':5,
    'title':'Je suis un agent de l’Institut français d’Algérie',
    'montant':0

  },{
    'id':6,
    'title':'Je suis admis dans le cadre d’une convention (co-diplomation, cotutelle ou codirection de thèse)',
    'montant':0

  },*/{
    'id':7,
    'title':'J’ai été absent à l’entretien',
    'montant':10000

  },{
    'id':8,
    'title':'Je ne suis dans aucun de ces cas',
    'montant':20000

  },]
    const wilayas = [
        /*{
            "id": 16,
            "code": "16",
            "name": "Alger",
            "ar_name": "الجزائر"
        },*/
        {
          "id": 23,
          "code": "23",
          "name": "Annaba",
          "ar_name": "عنابة"
        },
        {
          "id": 25,
          "code": "25",
          "name": "Constantine",
          "ar_name": "قسنطينة"
        },
        {
            "id": 31,
            "code": "31",
            "name": "Oran",
            "ar_name": "وهران"
        },
        {
            "id": 13,
            "code": "13",
            "name": "Tlemcen",
            "ar_name": "تلمسان"
        }
    ]
    const wilayasList = wilayas.map((wilaya,index)=>{
        return <option key={wilaya.id}>{`Campus France ${wilaya.name}`}</option>
      })

      const tarifsList = tarifs.map((tarif,index)=>{
        return <option key={tarif.id} value={tarif.id}>{`${tarif.title}`}</option>
      })
    

    //=========
    // const initialValues = { nom: "", prenom:"",numDz:"",telephone:"",naissance:"",email: "",wilaya:"",montant:""};
    // const [formValues, setFormValues] = useState(initialValues);
    // const [formErrors, setFormErrors] = useState({});
    // const [isOpen,setIsOpen] = useState(false)
    // const [payment,setPayment] = useState(false)
    // const [tarif,setTarif] = useState(0)
    // const [startDate, setStartDate] = useState(new Date());
    // // condition d'utilisation
    // const [isChecked,setIsChecked] = useState(false)
    // // recapthcha state
    //   const [isRecaptcha,setIsRecaptcha] = useState(false)


    const handleChange = (e) => {
        const { name, value } = e.target;
        // ==== use it later in the seccussefulPaymentpage
          // const newDate = name==="naissance"&&`${value.slice(8,10)}-${value.slice(5,7)}-${value.slice(0,4)}`
          // name==="naissance"&&console.log(newDate)

          console.log(name+ ' : '+ value)
          setFormValues({ ...formValues, [name]: value});
          if(name==='tarif'){
            var v=tarifs.find(x=>x.id===Number(value)).montant
            setFormValues({ ...formValues, [name]: v, tarif:v});
            setTarif(v)
            setTObject(value)
            handlePriceClick(v)
          }
      };

      const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        if (Object.keys(validate(formValues)).length === 0 && isChecked && isRecaptcha){
            setIsOpen(true)
        }
        // const orderId = Math.floor(Math.random() * 1000);
        // const textOrderId = orderId.toString()
        // fetch data from browser

        // var data = JSON.stringify({"total":"0","orderId":textOrderId,"username":"test"});


        // };
        // axios(config)
        // .then(function (response) {
        //   if (response.status===200 && response.data !== "") {
        //     window.location.replace(response.data)
        //   }
        //   console.log("rsponnse SubscriptionFrom")
        //   console.log(JSON.stringify(response.data));
        //   // navigate(response.data)
        // })
        // .catch(function (error) {
        //   console.log(error);
        // });
               };

      // validation function
      const validate = (values) => {
        const errors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const numDzRegex = /DZ\d{2}-\d{5}/
        const testNumDz = (num)=>{
          function isNumber(char) {
            return /^\d$/.test(char);
          }
          if (num[0]==="D" && num[1]==="Z" && num[4]==='-' && isNumber(num[2]) && isNumber(num[3]) && isNumber(num[5]) && isNumber(num[6]) && isNumber(num[7]) && isNumber(num[8]) && isNumber(num[9]) && num.length===10) {
            return true
          }else{
            return false
          }
        }
        const testPhoneNumber = (number)=>{
          function isNumber(char) {
            return /^\d$/.test(char);
          }
            if (number[0]==="0" && number.length===10 && isNumber(number[1]) && isNumber(number[2]) && isNumber(number[3]) && isNumber(number[4]) && isNumber(number[5]) && isNumber(number[6]) && isNumber(number[7]) && isNumber(number[8]) && isNumber(number[9])) {
              return true
            } else {
              return false
            }
        }
        if (!values.nom) {
          errors.nom = "Ce champ est obligatoire";
        }
        if (!values.email) {
          errors.email = "Ce champ est obligatoire";
        } else if (!emailRegex.test(values.email)) {
          errors.email = "L'adresse email n'est pas valide";
        }
        if (!values.prenom) {
          errors.prenom = "Ce champ est obligatoire";
        }
        if (!values.numDz) {
          errors.numDz = "Ce champ est obligatoire";
        }else if( !testNumDz(values.numDz)){
          errors.numDz = "L'identifiant n'est pas valide";
        }
        if (!values.telephone) {
          errors.telephone = "Ce champ est obligatoire";
        }else if(!testPhoneNumber(values.telephone)){
          errors.telephone = "Le numéro de téléphone n'est pas valide"
        }
        if (!values.naissance) {
          errors.naissance = "Ce champ est obligatoire";
        }
        if (!values.wilaya) {
          errors.wilaya = "Ce champ est obligatoire";
        }
        if (!values.montant) {
          errors.montant = "Il faut choisir un tarif";
        }if (!values.recaptcha && !isRecaptcha) {
          errors.recaptcha = "Il faut completer le reCAPTCHA";
        }
        return errors;
      };

      // handle colsing confirmaiton popup
      const handleClose =(p)=>{
        if(p)
        setLoading(true)
        setIsOpen(false)
        setPayment(p)
        console.log("xxxx:"+p)
        // if (p) {
        //   console.log('eb3et emaaaaaaaaaaaaaaaaaaaaaaaail to ' + formValues.email)
        // // send confiramtion email to the user
        //   handleConfirmationEmail(formValues.email)
        // }
      }

      // when payment == true : navigate to the success page
      useEffect(()=>{
        // payment&&navigate('processing',{state:{...formValues}})
        // payment&&window.location.replace('http://localhost:3000/https://test.satim.dz/payment/merchants/merchant1/payment_fr.html?mdOrder=HBzYAjKglPcT5AAACMEH');
        if(payment===true){
          const orderId = Math.floor(Math.random() * 1000);
        const textOrderId = orderId.toString()
        var config = {
          method: 'post',
          url: `https://campus.if-algerie.com/api/cibweb/initpayement?orderid=${textOrderId}&total=${formValues.tarif/10}&username=${formValues.email}&birthday=${formValues.naissance}&price=${formValues.montant}&number=${formValues.numDz}&phone=${formValues.telephone}&wilaya=${formValues.wilaya}&name=${formValues.nom}&surname=${formValues.prenom}`,
          // url: `https://campus.if-algerie.com/api/cibweb/initpayement?orderid=${textOrderId}&total=${formValues.montant/10}&username=${formValues.email}`,

          headers: {
            'Content-Type': 'application/json'
          }
          
        };
        axios(config)
        .then(function (response) {
          console.log("yyyy"+tarif)
          if (response.status===200 && response.data !== "") {
            if(tarif==='0'){
              navigate({pathname:'processing',search: `?orderId=${textOrderId}`})
            }else{
              window.location.replace(response.data)
            }
            // console.log('******')
            // console.log(response.data)
          }
          // console.log("rsponnse SubscriptionFrom")
          // console.log(JSON.stringify(response.data));
          // navigate(response.data)
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
        
        // send confiramtion email to the user
          // handleConfirmationEmail(formValues.email,formValues.nom,formValues.prenom)
        
        }
        
             
        
      },[payment])

      // handle price selection
      const handlePriceClick=(selectedPrice)=>{
        setTarif(selectedPrice)
        setFormValues({ ...formValues, montant:selectedPrice, tarif:selectedPrice})
        
      }


      // condition d'utilisation
      const handleCheckChange = () => {
        setIsChecked(!isChecked);
      };

      // date picker
      const [startDate, setStartDate] = useState('');
      
      // popover for NumDz help message
      const NumDzPopover = (
        <Popover id="popover-basic">
          <Popover.Body>
          Le format de l'identifiant DZ doit correspondre à l'exemple suivant "DZ22-00000"
          </Popover.Body>
        </Popover>
      );

      // popover for phone help message
      const phonePopover = (
        <Popover id="popover-basic">
          <Popover.Body>
          Le format du numéro de téléphone doit correspondre à l'exemple suivant « 0770022250 » sans espaces et sans indicatif pays.
          </Popover.Body>
        </Popover>
      );

      // popover for CVV2
      const cvvPopover = (
        <Popover id="popover-basic" >
          <Popover.Body className='w-200'>
          Le numéro de sécurité CVV2 (Card Verification Value) est un code de sécurité
          qui a été introduit par les compagnies bancaires afin de lutter contre la fraude
          à la carte de crédit sur internet. La procédure stipule que le détenteur de
          la carte entre le code CVV2 lors de la transaction.  Ce cryptogramme visuel désigne
          en général les trois derniers chiffres présents au dos d'une carte de paiement.
          <img src={cvv} className="w-100 d-block mx-auto my-2"/>
          </Popover.Body>
        </Popover>
      );
      
        // popover for Espace Campus France help message
      const espacePopover = (
        <Popover id="popover-basic">
          <Popover.Body>
          Votre espace Campus France de rattachement est mentionné dans votre espace personnel sur la plateforme Études en France. Il doit être identique pour le bon traitement de votre dossier Études en France par les équipes Campus France Algérie.
          </Popover.Body>
        </Popover>
      );

      const tarifPopover = (
        <Popover id="popover-basic">
          <Popover.Body>
          Avant d’effectuer votre paiement, veuillez-vous renseigner sur le statut à sélectionner dans le menu déroulant, ce qui vous permettra de connaître le tarif adapté à votre situation. En cas d’erreur, aucun remboursement ne sera possible 
          </Popover.Body>
        </Popover>
      );
     
      
     
  return (
    <div className='ms-md-5 me-md-5 mx-auto my-4 text-start '>
      {loading ?<>
        <Container>
      <div className='py-3 d-flex mb-5 flex-lg-row align-items-center justify-content-between'>
        <img src={campus} alt="Campus France" width='160px'/>
        <img src={satim} alt="satim" width='200px'/>
      </div>
      <div className=' text-center pb-5 mt-2'>
        
        <p className=' fs-4 mb-5'>Le traitement de votre paiement est en cours <br/> patientez SVP</p>
        
        <div className='d-flex justify-content-center align-items-center mb-5 mt-2'>
        <InlineDotsLoader className="mt-2" />
        </div>
        </div>
        </Container>
      </>
          
          
          :
      <Form className='bg-white p-3 rounded' onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col md={6}>
              <Form.Group controlId="formGridEmail">
                <Form.Label>Nom</Form.Label>
                <Form.Control className='rounded-0' type="text" name="nom" value={formValues.nom} onChange={handleChange}  />
                <p className='text-danger fw-light mt-1' style={{fontSize:"14px"}}>{formErrors.nom}</p>
              </Form.Group>
          </Col>
          <Col md={6}>
              <Form.Group controlId="">
                <Form.Label>Prénom</Form.Label>
                <Form.Control className='rounded-0' type="text" name="prenom" value={formValues.prenom} onChange={handleChange}  />
                <p className='text-danger mt-1 fw-light' style={{fontSize:"14px"}}>{formErrors.prenom}</p>
              </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
                <Form.Group className="mb-3" controlId="formGridAddress1">
                  <Form.Label>Date de naissance</Form.Label>
                  <Form.Control className='rounded-0' type="date" name="naissance" value={formValues.naissance} onChange={handleChange}/>
                  <p className='text-danger mt-1 fw-light' style={{fontSize:"14px"}}>{formErrors.naissance}</p>
                </Form.Group>              
          </Col>
          <Col md={6}>
                <Form.Group className="mb-3" >
                  <Form.Label>Identifiant Campus France  </Form.Label>
                  
                  <OverlayTrigger trigger="click" placement="top" overlay={NumDzPopover}>
                      <span style={{cursor:"pointer"}}> <IoHelpCircleOutline className='blue-ifa fs-5 mb-1'/></span>
                  </OverlayTrigger>
                  <Form.Control className='rounded-0' type="text" name="numDz" placeholder='DZXX-XXXXXX' value={formValues.numDz} onChange={handleChange} />
                  <p className='text-danger mt-1 fw-light' style={{fontSize:"14px"}}>{formErrors.numDz}</p>
                </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
              <Form.Group className="mb-3" >
                <Form.Label>Numéro de téléphone </Form.Label>
                <OverlayTrigger trigger="click" placement="top" overlay={phonePopover}>
                      <span style={{cursor:"pointer"}}> <IoHelpCircleOutline className='blue-ifa fs-5 mb-1'/></span>
                </OverlayTrigger>
                <Form.Control className='rounded-0' type="text" name="telephone" value={formValues.telephone} onChange={handleChange}  />
                <p className='text-danger mt-1 fw-light' style={{fontSize:"14px"}}>{formErrors.telephone}</p>
              </Form.Group>
          </Col>
          <Col md={6}>
              <Form.Group className="mb-3"  >
                <Form.Label>Adresse email </Form.Label>
                <Form.Control className='rounded-0' type="email" name="email" value={formValues.email} onChange={handleChange}/>
                <p className='text-danger mt-1 fw-light' style={{fontSize:"14px"}}>{formErrors.email}</p>
              </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridState">
            <Form.Label>Espace Campus France de rattachement</Form.Label>
            <OverlayTrigger trigger="click" placement="top" overlay={espacePopover}>
              <span style={{cursor:"pointer"}}> <IoHelpCircleOutline className='blue-ifa fs-5 mb-1'/></span>
            </OverlayTrigger>
            <Form.Select className="rounded-0" defaultValue="Choose..."  name="wilaya" value={formValues.wilaya} onChange={handleChange}>
              <option></option>
              {wilayasList}
            </Form.Select>
            <p className='text-danger mt-1 fw-light' style={{fontSize:"14px"}}>{formErrors.wilaya}</p>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridState">
            <Form.Label>Votre statut de dossier Étude en France  *</Form.Label>
            <OverlayTrigger trigger="click" placement="top" overlay={tarifPopover}>
              <span style={{cursor:"pointer"}}> <IoHelpCircleOutline className='blue-ifa fs-5 mb-1'/></span>
            </OverlayTrigger>
            <Form.Select className="rounded-0" defaultValue="Choose..."  name="tarif" value={tObject} onChange={handleChange}>
              <option></option>
              {tarifsList}
            </Form.Select>
            <p className='text-danger mt-1 fw-light' style={{fontSize:"14px"}}>{formErrors.montant}</p>
          </Form.Group>
        </Row>


        <div className='text-center d-flex flex-column align-items-center justify-content-center'>
          <RECAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} onExpired={()=>setIsRecaptcha(false)} onChange={()=>setIsRecaptcha(true)} className="mb-2" required/>
          <p className='text-danger fw-light mt-1' style={{fontSize:"14px"}}>{formErrors.recaptcha}</p>
        </div>
        
        <div className='mt-4 text-center'>
          <small className='d-block'>* Les frais de dossiers Études en France ne sont pas remboursables</small>
          <small>
            ** Plus d'informations sur la tarification,  
            <a href='https://www.algerie.campusfrance.org/faq/quels-sont-les-frais-de-dossier-a-payer-pour-la-candidature-campus-france-algerie-20222023' target="_blank"> cliquez ici</a> 
          </small>
        </div>

        <div className='text-center mt-4'>
          <input type="checkbox" name="condition" id="condition" checked={isChecked} onChange={handleCheckChange} required/>
          <label htmlFor='condition' className='ms-2'>
            <small>J'accepte les 
            {/* <a href='https://www.algerie.campusfrance.org/faq/quels-sont-les-frais-de-dossier-a-payer-pour-la-candidature-campus-france-algerie-20222023' target="_blank"> conditions d'utilisation</a>  */}
            <Link to="conditions-d-utilisation" target="_blank"> conditions d'utilisation</Link>
            </small>
            </label>
        </div>

        <div className='text-center mt-3'>
          <small>Munissez-vous de votre carte de crédit avec le numéro CVV2. </small>
          <OverlayTrigger trigger="click" placement="top" overlay={cvvPopover}>
                <span style={{cursor:"pointer"}}> <IoHelpCircleOutline className='blue-ifa fs-5 mb-1'/></span>
          </OverlayTrigger>
        </div>
        <div className='d-flex justify-content-center mt-3'>
            <Button  type="submit" className="rounded-0 p-2 " style={{backgroundColor: 'rgb(0, 146, 213)'}} >
                Payer maintenant
                <img src={cib} style={{width:"70px",marginLeft:"10px"}}/>
            </Button>
        </div>
        
      </Form>}
      <Modal open={isOpen} onClose={handleClose}>
          <ConfirmationPopUp tarif={tarif} onClose={handleClose}/>
      </Modal>
    </div>
  );
}

export default CampusSubscriptionForm


