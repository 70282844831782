import React from 'react'
// import redShape from "../../img/bg-shapes-svg/shape-red.svg"
import blueShape from "../../img/bg-shapes/shape-blue.png"
import redShape from "../../img/bg-shapes/shape-red.png"
import yellowShape from "../../img/bg-shapes/shape-yellow.png"
import doubleYellowShapes from "../../img/bg-shapes/shape2-yellow.png"
import doubleWhiteShapes from "../../img/bg-shapes/shape-white.png"

function BgShape({left,right,top,bottom,type,rotate,className}) {

    const blueStyle={
        position: "absolute",
        zIndex:"-1",
        top:top,
        left:left,
        right:right,
        bottom:bottom,
        transform:`rotate(${rotate})`,
        // left:position=="left"?"-120px":"",
        // right:position=="right"?"-130px":"",
        // top:position=="left"?"800px" : "",
        // transform:position=="left"?"rotate(140deg)":"right"?"rotate(50deg)":"",
        
    }

    const selectedShape = (type) => {
      switch (type) {
        case "red":
          return redShape;
          break;
        case "blue":
          return blueShape;
          break;
        case "yellow":
          return yellowShape;
          break;
        case "2yellow":
          return doubleYellowShapes;
          break;
        case "2white":
          return doubleWhiteShapes;
          break;

        default:
          break;
      }
    };

  return (
    <div style={blueStyle} className={className}>
        <img src={selectedShape(type)} className="w-75"/>
    </div>
  )
}

export default BgShape

